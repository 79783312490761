import React, {
  useContext,
  useState,
  useRef,
  forwardRef,
  useEffect,
} from "react";
import { useHistory } from "react-router-dom";
import Occupation from "../highcharts/Occupation";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Slide from "@material-ui/core/Slide";
import { AuthContext } from "../../context/AuthProvider";
import {
  change_status_area,
  change_color_title_plugin,
} from "../../utils/helpers.js";
import BuildIcon from "@mui/icons-material/Build";
import Swal from "sweetalert2";
import { useQuery } from "@apollo/client";
import { areas_with_device_fails, filterByPluggin } from "../../utils/helpers";
import DevicesFails from "../devices/DevicesFails";
import IBMFails from "../devices/IBMFails";
import Temperature from "../highcharts/Temperature";
import { Hidden } from "@material-ui/core";
import { secondClient } from "../../index";
import LoadingButton from "@mui/lab/LoadingButton";
import DownloadIcon from "@mui/icons-material/Download";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  FindSerialPluginbathroom,
  CheckStatusPluginBathroom,
} from "../../utils/helpers";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import PluginMode from "./plugin/PluginMode";

//components
import PluginBathroomComp from "./plugin/PluginBathroom";

//query and mutation
import { useMutation } from "@apollo/client";
import { PDF_OCUPATION_BY_ROOM } from "../../controllers/pdfControllers";
import { GET_METER_HISTORY_VARIABLES } from "../../controllers/measurersController";
//multilanguage
import { useTranslation } from "react-i18next";
import OcupationTable from "../reports/Ocupation/OcupationTable";
import Ocupation from "../reports/Ocupation/Ocupation";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const customBlue = "rgb(13, 112, 178)";
const customColors = createTheme({
  palette: {
    secondary: {
      main: customBlue,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#3f9948",
    position: "relative",
  },
  appBarDeviceFails: {
    backgroundColor: "#FFAF0E",
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  graphic: {
    marginTop: 30,
  },
  image: {
    position: "relative",
    height: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
  table: {
    minWidth: 700,
  },
  info: {
    marginBottom: 60,
  },
  tab: {
    marginTop: 60,
  },
}));

const Area = (props) => {
  const { t } = useTranslation();
  const [pdfGenerator] = useMutation(PDF_OCUPATION_BY_ROOM);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const {
    area,
    setIdArea,
    setArea,
    setSerial,
    setactiveNextBack,
    setDateCalendar,
    loadingPdf,
    setCalendarDatePicker,
    occupationAreaName,
    setOccupationAreaName,
    setDateCalendarTemp,
    nameHeaderOcupation,
    setNameHeaderOcupation,
    setCalendarDatePickerToday
  } = useContext(AuthContext);
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [openTemp, setOpenTemp] = useState(false);
  const [openDevicesFails, setOpenDevicesFails] = React.useState(false);
  const [openIBMFails, setOpenIBMFails] = React.useState(false);
  const classes = useStyles();
  const childRef = useRef();
  const [client, setClient] = useState(null);
  const [serialPluginBathroom, setSerialPluginBathroom] = useState(null);
  const [initialState, setInitialState] = useState([]);
  const [cloneDataState, setCloneDataState] = useState({});
  const [selectedRoom, setSelectedRoom] = useState("");
  const statePlugin = props?.data?.Device?.find(
    (i) =>
      i?.visible &&
      i?.Category?.category === 600 &&
      i?.Category?.sub_category === 3
  );

  // const thermostatExists = props?.data?.Device?.find((element) =>
  //   (element?.visible &&
  //     element?.Category?.category == "5" &&
  //     element?.Category?.sub_category == "1") ||
  //   (element?.visible &&
  //     element?.Category?.category === "hvac" &&
  //     element?.Category?.sub_category === "hvac")
  //     ? true
  //     : false
  // );

  // const temperatureSensor = props?.data?.Device?.find(
  //   (element) =>
  //     element?.visible &&
  //     element?.Category?.category == "17" &&
  //     element?.Category?.sub_category == "0"
  // )
  //   ? true
  //   : false;

  const {
    REACT_APP_SOCKET_PRODUCTION,
    REACT_APP_SOCKET_DEVELOP,
    REACT_APP_SOCKET_STAGING,
  } = process.env;

  useEffect(() => {
    if (props) {
      const result = FindSerialPluginbathroom(props);
      if (result) {
        if (result.serial !== null) {
          setSerialPluginBathroom(result.serial);
          //console.log("encontre serial", result.serial);
        }
        if (result.statePlugin !== null) {
          setInitialState(result.statePlugin);
          // console.log("encontre los estados", result.statePlugin);
        }
      } else {
        //console.log("No se encontró serial ni estado");
      }
    }
  }, [props]);

  useEffect(() => {
    if (serialPluginBathroom !== null) {
      if (client) {
        client.onerror = client.onopen = client.onclose = null;
        client.close();
      }
      const newClient = new W3CWebSocket(`${REACT_APP_SOCKET_PRODUCTION}`);
      newClient.onopen = () => {
        if (serialPluginBathroom)
          newClient.send(JSON.stringify({ channel: serialPluginBathroom }));
      };
      newClient.onerror = function () {
        console.log("Se ha producido un error en el cliente del socket");
      };
      newClient.onmessage = function (e) {
        if (typeof e.data === "string") {
          const response = JSON.parse(e.data);

          setInitialState((prevState) =>
            prevState.map((item) =>
              item.id === response.id_device
                ? {
                    ...item,
                    variables: { ...item.variables, Status: response.status },
                  }
                : item
            )
          );
        } else {
          // console.log("Mensaje recibido no es una cadena");
        }
      };
      newClient.onclose = () => {
        setClient(null);
      };
      setClient(newClient);
      // Limpia la conexión al desmontar el componente
      return () => {
        if (newClient) newClient.close();
      };
    }
  }, [serialPluginBathroom]);

  function openArea() {
    localStorage.setItem("RoomName", props.data.alias);
    localStorage.setItem("id", props.project.id);
    localStorage.setItem("typeGateway", props.data?.Controller.typeGateway);

    setArea(props.data.alias);
    setIdArea(props.data.id);
    setSerial(props.data.Controller.serial);
    let aux = localStorage.getItem("typeGateway");
    if (aux === "horus_g150") {
      history.push(
        `/app/devices/${props.data.id}-${props?.data?.Controller?.typeGateway}`
      );
    } else if (aux === "horus_700") {
      history.push(
        `/app/newdevices/${props.data.id}-${props?.data?.Controller?.typeGateway}`
      );
    }
    if (!props.data.Controller.online) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Área fuera de línea",
      });
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
    localStorage.setItem("RoomName", props?.data?.alias);
    setArea(props?.data?.alias);
    setIdArea(props?.data?.id);
    localStorage.setItem("selectedAreaName", props?.data?.name);
    setNameHeaderOcupation(props?.data?.name); // Add select name header
    setOccupationAreaName(localStorage.getItem("selectedAreaName"));

    const day_by_miliseconds = 86400000;
    // Hora actual
    const actual_hour = new Date().getHours();
    // Hora inicio
    const hotel_hour = Number(
      localStorage.getItem("startTime").substring(0, 2)
    );
    setCalendarDatePicker(
      actual_hour < hotel_hour
        ? new Date(new Date().getTime() - day_by_miliseconds)
        : new Date()
    );
  };

  const handleClickOpenTemp = () => {
    setOpenTemp(true);
    setactiveNextBack(true);
    setCalendarDatePicker(new Date());
    setCalendarDatePickerToday(new Date())
  };

  const hndleCloseTemp = () => {

    setDateCalendar(new Date());
    setDateCalendarTemp(new Date());
    setOpenTemp(false);
  };

  const handleDeviceFail = () => {
    setOpenDevicesFails(true);
  };

  const handleClose = () => {
    setCalendarDatePicker(new Date());
    setOpen(false);
  };

  const handleCloseDeviceFails = () => {
    setOpenDevicesFails(false);
  };

  const handleCloseIBMFails = () => {
    setOpenIBMFails(false);
  };

  const handleOpenIBMFails = () => {
    setOpenIBMFails(true);
  };

  const FilterDevicesFails = () => {
    return props?.data?.Device?.filter(
      (i) => i.variables.CommFailure == "1" && i.visible
    );
  };

  const device_off = areas_with_device_fails(props.data);

  return (
    <div className={`_area_container`}>
      <div
        title={t("VIEW_AREAS.COMPONENT_AREA_CARD.CARD_HEADER_TOOLTIP")}
        className={`${filterByPluggin(props)} _area_container_title ${
          device_off > 0
            ? "_area_container_title_true"
            : "_area_container_title_false"
        }`}
      >
        <span
          className={`${change_color_title_plugin(statePlugin)} __title_area`}
          onClick={openArea}
        >
          {props?.data?.alias}
        </span>

        {device_off > 0 ? (
          <div className="failed_device_button">
            <span
              onClick={handleDeviceFail}
              className="__icon_warning__"
              title={
                device_off > 0
                  ? t(
                      "VIEW_AREAS.COMPONENT_AREA_CARD.CARD_HEADER_DEVICES_NOTIFICATION_TOOLTIP"
                    ) + `${device_off}`
                  : ""
              }
            >
              <ThemeProvider theme={customColors}>
                <BuildIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    height: "13px",
                    width: "14px",
                  }}
                  color="secondary"
                />
              </ThemeProvider>
            </span>
          </div>
        ) : (
          false
        )}
        <PluginMode props={props} />
      </div>
      <div
        className={`_area_container_reports ${
          !props?.data?.Controller?.online ? "__area_controller_off__" : false
        }`}
        onClick={openArea}
      >
        <div
          className={`_area_container_report_icon ${
            CheckStatusPluginBathroom(initialState) ||
            cloneDataState.isStatusOne === true
              ? "active_fails_devices"
              : "desactive_fails_devices"
          }`}
        >
          <span
            title={t(
              "VIEW_AREAS.COMPONENT_AREA_CARD.CARD_BODY_OCUPATION_TOOLTIP"
            )}
            className="_area_container_img_ocupation"
            onClick={(e) => {
              e.stopPropagation();
              handleClickOpen();
            }}
          >
            <i className="zmdi zmdi-male"></i>
          </span>

          <span
            className={`${"_area_container_img_energy"}`}
            // className={`${
            //   thermostatExists || temperatureSensor
            //     ? "_area_container_img_energy"
            //     : "_area_container_disabled_img_energy"
            // }`}

            onClick={(e) => {
              e.stopPropagation();
              handleClickOpenTemp();
            }}

            // onClick={
            //   thermostatExists || temperatureSensor
            //     ? () => handleClickOpenTemp()
            //     : null
            // }
          >
            <i className="zmdi zmdi-flare"></i>
          </span>

          <span className="_area_container_fails_socket">
            {CheckStatusPluginBathroom(initialState) ? (
              <span onClick={() => handleOpenIBMFails()}>
                <PluginBathroomComp />
              </span>
            ) : null}
          </span>
        </div>
      </div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              className={classes.title}
              variant={innerWidth <= 680 ? "h7" : "h6"}
              align={innerWidth <= 680 ? "center" : "left"}
            >
              {`${props?.project?.name} - ${t(
                "VIEW_AREAS.COMPONENT_AREA_CARD.COMPONENT_FULL_MODAL_OCCUPATION.HEADER_TITLE"
              )} ${nameHeaderOcupation}`}
            </Typography>

            <Hidden mdDown>
              <LoadingButton
                // disabled={roomPdf.rooms.length == 0 || roomPdf == undefined}
                onClick={() => childRef.current.occupationPdf()}
                endIcon={<DownloadIcon />}
                loadingPosition="end"
                color="inherit"
              >
                {t(
                  "VIEW_AREAS.COMPONENT_AREA_CARD.COMPONENT_FULL_MODAL_OCCUPATION.DOWNLOAD"
                )}
              </LoadingButton>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme?.zIndex?.drawer + 1,
                }}
                open={loadingPdf ? true : false}
                onClick={loadingPdf}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </Hidden>
          </Toolbar>
        </AppBar>
        {/* <OcupationTable /> */}
        <Ocupation
          project={props?.project}
          RoomId={props?.data?.id}
          roomIdDatepicker={props?.data?.id}
          NewRoomId={props?.data?.NewRoomId}
          typeGateway={props?.data?.Controller?.typeGateway}
          ref={childRef}
        />
      </Dialog>

      <Dialog
        fullScreen
        open={openTemp}
        onClose={hndleCloseTemp}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={hndleCloseTemp}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              className={classes.title}
              variant={innerWidth <= 680 ? "h7" : "h6"}
              align={innerWidth <= 680 ? "center" : "left"}
            >
              {`${props?.project?.name} - ${t(
                "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_MODAL_TEMPERATURE.MODAL_HEADER_TITLE"
              )} ${props?.data?.name}`}
            </Typography>
          </Toolbar>
        </AppBar>
        <Temperature
          typeGateway={props?.data?.Controller?.typeGateway}
          RoomId={props?.data?.id}
          NewRoomId={props?.data?.NewRoomId}
          project={props?.project}
        />
      </Dialog>

      <Dialog
        fullWidth
        open={openDevicesFails}
        onClose={handleCloseDeviceFails}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBarDeviceFails}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDeviceFails}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              className={classes.title}
              variant={innerWidth <= 680 ? "h7" : "h6"}
              align={innerWidth <= 680 ? "center" : "left"}
            >
              {props?.project?.name}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="__title_device_fails__">
          {t(
            "VIEW_AREAS.COMPONENT_AREA_CARD.COMPONENT_DEVICE_MODAL_FAILURE.MODAL_TITLE"
          ) + `${device_off}`}
        </div>
        {FilterDevicesFails()?.map((device, key) => (
          <DevicesFails key={key} device={device} />
        ))}
      </Dialog>

      <Dialog
        fullWidth
        open={openIBMFails}
        onClose={handleCloseIBMFails}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBarDeviceFails}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseIBMFails}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              className={classes.title}
              variant={innerWidth <= 680 ? "h7" : "h6"}
              align={innerWidth <= 680 ? "center" : "left"}
            >
              {props?.project?.name}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="__title_device_fails__">{`Estado activos del baño`}</div>
        {<IBMFails stateinitial={initialState} stateClone={cloneDataState} />}
      </Dialog>
    </div>
  );
};

export default Area;
