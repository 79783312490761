import React, { useState, useEffect, useContext, useRef } from "react";

//Material UI components
import {
  Dialog,
  AppBar,
  Typography,
  Slide,
  IconButton,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useTheme } from "@mui/material/styles";

//Apollo hooks and queries
import { useLazyQuery } from "@apollo/client";
import {
  GET_BATTERY_DEVICES,
  GET_BATTERY_NEW_DEVICES,
} from "../../../controllers/reportController";

//Components
import BatteryLevel from "../BatteryLevel";
import BatteryNoData from "./BatteryNoData";
import GraphBatteryLevel from "./GraphBatteryLevel";

//multilanguage
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#3f9948",
    position: "fixed",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GraphBatteryModal = ({
  open,
  name,
  handleClose,
  id,
  areaName,
  typeGateway,
}) => {
  const [getDataBatteryReport, { data: dataBattery150, loading: loading150 }] =
    useLazyQuery(GET_BATTERY_DEVICES);

  const [
    getDataNewBatteryReport,
    { data: dataBattery700, loading: loading700 },
  ] = useLazyQuery(GET_BATTERY_NEW_DEVICES);

  const [dataBattery, setDataBattery] = useState();
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const query700 = {
    variables: {
      itemId: id,
    },
  };
  const query150 = {
    variables: {
      DeviceId: parseInt(id, 10),
    },
  };

  useEffect(() => {
    if (typeGateway === "horus_700") {
      getDataNewBatteryReport(query700);
    } else if (typeGateway === "horus_g150") {
      getDataBatteryReport(query150);
    }
  }, []);

  useEffect(() => {
    setDataBattery(dataBattery150 || dataBattery700);
  }, [dataBattery150, dataBattery700]);

  return (
    <>
      {dataBattery?.getAllNewDeviceByBatteryLevel?.data?.length ||
      dataBattery?.getDeviceByBatteryLevel?.data?.length !== 0 ? (
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant={"h6"}>
                {`${localStorage.getItem("nameProject")} - ${t(
                  "VIEW_AREA.COMPONENT_FULL_MODAL_BATTERY.MODAL_HEADER"
                )} ${name} - ${areaName}`}
              </Typography>
            </Toolbar>
          </AppBar>

          <GraphBatteryLevel data={dataBattery} typeGateway={typeGateway} />

          <BatteryLevel
            data={dataBattery}
            name={name}
            id={id}
            typeGateway={typeGateway}
          />
        </Dialog>
      ) : (
        <>
          <BatteryNoData open={open} close={handleClose} name={name} />
        </>
      )}
    </>
  );
};
export default GraphBatteryModal;
