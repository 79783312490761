//import
import { useLocation } from "react-router-dom";
/**   */
//Constants
const DAYS = {
  Mon: "Lun",
  Tue: "Mar",
  Wed: "Mie",
  Thu: "Jue",
  Fri: "Vie",
  Sat: "Sab",
  Sun: "Dom",
};

const MONTHS = {
  Jan: "Ene",
  Feb: "Feb",
  Mar: "Mar",
  Apr: "Abr",
  May: "May",
  Jun: "Jun",
  Jul: "Jul",
  Aug: "Ago",
  Sep: "Sep",
  Oct: "Oct",
  Nov: "Nov",
  Dec: "Dic",
};

export const MONTHS_NUMBER = {
  1: "Ene",
  2: "Feb",
  3: "Mar",
  4: "Abr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Ago",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dic",
};

export const rename_area = (name) => {
  return `${name.replace(/Habitacion|Habitación|Hab/g, "")}`;
};

export const last_conection = (status, t) => {
  return !status ? t("VIEW_CONTROLLERS.OFFLINE") : t("VIEW_CONTROLLERS.ONLINE");
};

export const change_status_area = (statu, pluggin) => {
  const status_motel = {
    0: "libre_motel_plugin",
    25: "sucia_motel_plugin",
    50: "vendida_motel_plugin",
    75: "aseo_motel_plugin",
    100: "bloqueada_motel_plugin",
    125: "reservada_motel_plugin",
  };
  const status_pluggin_motel = status_motel[pluggin?.variables.Estado];
  if (Boolean(pluggin)) return status_pluggin_motel ?? "__status_null_area";
  if (statu == "Libre") {
    return "__status_libre_area";
  } else if (statu == "Ocupado") {
    return "__status_ocupado_area";
  } else {
    return "__status_null_area";
  }
};

export const change_color_title_plugin = (pluggin) => {
  if (!Boolean(pluggin)) return "color_standar_pluggin";
  const status_pluggin_motel = pluggin?.variables.Estado;
  if (status_pluggin_motel == "0") {
    return "color_title_pluggin";
  } else {
    return "color_standar_pluggin";
  }
};

export const calc_controler_on_off = (controller_on, controller_off) => {
  return (100 - (controller_off / controller_on) * 100) | 0;
};

export const color_status_project = (on, off) => {
  const percent = 100 - (off / on) * 100;
  if (percent == 0 || !percent) return "color_percent_0";
  else if (percent > 0 && percent <= 25) return "color_percent_25";
  else if (percent > 25 && percent <= 50) return "color_percent_50";
  else if (percent > 50 && percent <= 75) return "color_percent_75";
  else if (percent > 75 && percent <= 100) return "color_percent_100";
};

export const weather_convert = (temp) => {
  return (temp - 273.15).toFixed(1);
};

export const getTimeGlobal = (utc) => {
  const handleminutes = (minute) => (minute < 10 ? `0${minute}` : minute);
  const handleAmAndPm = (hour, minute) => {
    if (hour >= 13) {
      return `${hour - 12}:${handleminutes(minute)} PM`;
    } else if (hour == 12) {
      return `${hour}:${handleminutes(minute)} PM`;
    } else if (hour == 0) {
      return `${hour + 12}:${handleminutes(minute)} AM`;
    } else {
      return `${hour}:${handleminutes(minute)} AM`;
    }
  };

  const handleMeridian = (time) => {
    const getTime = time.split(":");
    const hours = Number(getTime[0]);
    const minutes = Number(getTime[1]);
    if (hours >= 24) {
      const new_hours = hours - 24;
      return handleAmAndPm(new_hours, minutes);
    } else {
      return handleAmAndPm(hours, minutes);
    }
  };

  const handleHoursAndMinutes = (time) => {
    const getTime = time.split(":");
    const hours = Number(getTime[0]);
    const minutes = Number(getTime[1]);
    if (minutes >= 60) {
      const new_minutes = minutes - 60;
      const new_hours = hours + 1;
      return `${new_hours}:${new_minutes}`;
    } else {
      return `${hours}:${minutes}`;
    }
  };

  const handleTimeWithFloat = (time_global, utc) => {
    const hours = time_global.substring(17, 19);
    const minutes = time_global.substring(20, 22);
    if (utc > 0) {
      const part_int_plus = Math.floor(utc);
      const new_hours = Number(hours) + part_int_plus;
      const new_minutes = Number(minutes) + 30;
      return `${new_hours}:${new_minutes}`;
    } else {
      const part_int_neg = Math.ceil(utc);
      if (minutes < 31) {
        const new_hours = Number(hours) + part_int_neg - 1;
        const new_minutes = Number(minutes) + 30;
        return `${new_hours}:${new_minutes}`;
      } else {
        const new_hours = Number(hours) + part_int_neg;
        const new_minutes = Number(minutes) - 30;
        return `${new_hours}:${new_minutes}`;
      }
    }
  };

  // If float or not
  if (utc) {
    const time_global = new Date().toUTCString();
    if (utc % 1 != 0) {
      return handleMeridian(handleHoursAndMinutes(handleTimeWithFloat(time_global, utc)));
    } else {
      const hours = time_global.substring(17, 19);
      const minutes = time_global.substring(20, 22);
      const time = `${(hours == 0 ? 24 : Number(hours) + 24) + utc}:${minutes}`;
      return handleMeridian(handleHoursAndMinutes(time));
    }
  } else {
    return `00:00`;
  }
};

export const handleSortAlphabet = (data) => {
  if (data.length == 0) return [JSON.parse(localStorage.getItem("project_selected"))];
  const new_array = [];
  const names = data.map((i) => i.name).sort();
  names.map((j) => {
    const x = data.filter((i) => i.name == j);
    new_array.push(x[0]);
  });
  return new_array;
};

export const data_fake = (data) => {
  return data;
};

export const sum_of_the_kwh = (data) => {
  return data.map((i) => Number(i.y)).reduce((a, b) => a + b, 0);
};

export const date_of_the_kwh = (dt, date_range) => {
  if (date_range.length > 1) {
    const aux = new Date(dt);
    return `${aux.getDate() + 1} ${
      MONTHS_NUMBER[aux.getMonth() + 1]
    } ${aux.getFullYear()}`;
  } else {
    return new Date(Number(dt)).toString().substring(16, 24);
  }
};

export const total_of_the_kwh = (data) => {
  return data
    ?.map((i) => i?.data?.map((i) => i?.y)?.reduce((a, b) => a + b, 0))
    ?.reduce((a, b) => a + b, 0);
};

export const calc_device_fails = (data) => {
  return data?.filter((i) => i.visible).length;
};

export const devices_fails = (data) => {
  return data.filter((i) => i?.variables?.CommFailure == "1" && i?.visible).length;
};

export const device_all = (device) => {
  return device.filter((i) => i.visible).length;
};

export const areas_with_device_fails = (data) => {
  return data?.Device?.filter((i) => i.variables.CommFailure == "1" && i.visible).length;
};

// Count controllers offline
export const controller_err = (controller) => {
  return controller.filter((item) => !item.online).length;
};

// Count rooms offline
export const room_err = (room) => {
  return room.filter((item) => !item.Controller.online).length;
};

export const room_all = (room) => {
  return room.filter((i) => i.visible).length;
};

// Count devices offline
export const device_err = (device) => {
  return device.filter(
    (i) =>
      i.variables != null &&
      i.variables.CommFailure != null &&
      i.variables.CommFailure != undefined &&
      i.variables.CommFailure == "1" &&
      i.visible
  ).length;
};

export const project_card_info = (data) => {
  let {
    img,
    name,
    time_zone,
    City,
    Controller,
    Device,
    Room,
    id,
    currency,
    end_time,
    kwh_cost,
    prefix_currency,
    start_time,
  } = data[0];

  return {
    img,
    name,
    time_zone,
    currency,
    end_time,
    kwh_cost,
    prefix_currency,
    city: City.name,
    start_time,
    country: City.State.Country.name,
    id,
    controllers_online: Controller.length,
    controllers_offline: controller_err(Controller),
    room_online: Room.length,
    room_offline: room_err(Room),
    device_error: device_err(Device),
    device_error_free: Device.length,
  };
};

export const calc_percent_watts = (watts) => {
  const value_max = 15000;
  const watt = Number(watts);
  return (watt * 100) / value_max;
};

export const convert_time_calendarCustom = (date) => {
  if (!date) {
    const dateActual = new Date();
    const day_week_start = dateActual.toString().substring(0, 3);
    const key_month_start = dateActual.toString().substring(4, 7);
    const day_start = dateActual.toString().substring(8, 10);
    const year_start = dateActual.toString().substring(11, 15);
    return `${DAYS[day_week_start]} ${day_start}, ${MONTHS[key_month_start]} ${year_start}`;
  }
  const day_week_start = date.toString().substring(0, 3);
  const key_month_start = date.toString().substring(4, 7);
  const day_start = date.toString().substring(8, 10);
  const year_start = date.toString().substring(11, 15);
  return `${DAYS[day_week_start]} ${day_start}, ${MONTHS[key_month_start]} ${year_start}`;
};

export const convert_time_measurergeneral = (dates) => {
  const day_week_start = dates[0].startDate.toString().substring(0, 3);
  const key_month_start = dates[0].startDate.toString().substring(4, 7);
  const day_start = dates[0].startDate.toString().substring(8, 10);
  const year_start = dates[0].startDate.toString().substring(11, 15);
  const day_week_end = dates[0].endDate.toString().substring(0, 3);
  const key_month_end = dates[0].endDate.toString().substring(4, 7);
  const day_end = dates[0].endDate.toString().substring(8, 10);
  const year_end = dates[0].endDate.toString().substring(11, 15);
  const end_range =
    new Date(dates[0].startDate.toString()).getTime() ==
    new Date(dates[0].endDate.toString()).getTime()
      ? ""
      : `-  ${DAYS[day_week_end]} ${day_end}, ${MONTHS[key_month_end]} ${year_end}`;
  return `${DAYS[day_week_start]} ${day_start}, ${MONTHS[key_month_start]} ${year_start}  ${end_range}`;
};

export const depuration_data_measurergeneral_report = (data) => {
  return data.filter((i) => i.data.length != 0);
};

export const date_last = (dates) => {
  const date = new Date();
  const date_now = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  if (date_now === dates[dates.length - 1]) {
    dates.pop();
    return dates;
  } else {
    return dates;
  }
};

export const calc_date_range = (dateReport) => {
  const date_start = new Date(dateReport[0].startDate.toString().substring(0, 16));
  const date_end = new Date(dateReport[0].endDate.toString().substring(0, 16));
  const result = [];
  while (date_end.getTime() > date_start.getTime()) {
    result.push(
      `${date_start.getFullYear()}-${date_start.getMonth() + 1}-${date_start.getDate()}`
    );
    date_start.setDate(date_start.getDate() + 1);
  }
  result.push(
    `${date_end.getFullYear()}-${date_end.getMonth() + 1}-${date_end.getDate()}`
  );
  return result;
};

export const handleTime = (state) => {
  const start_time = `${state[0].startDate.toString().substring(0, 16)} 00:00:00 GMT`;
  const end_time = `${state[0].endDate.toString().substring(0, 16)} 23:59:59 GMT`;
  return {
    start_time: new Date(start_time).getTime(),
    end_time: new Date(end_time).getTime(),
  };
};

export const cacl_color_graph_measurer = (watts) => {
  const value = parseInt((100 * parseFloat(watts)) / 15000);
  if (value == NaN) return "#3f9948";
  if (value > 0 && value <= 25) {
    return "#3f9948";
  } else if (value > 25 && value <= 50) {
    return "#FFAF0E";
  } else if (value > 50 && value <= 75) {
    return "#ff660e";
  } else if (value > 75 && value <= 90) {
    return "#bf2626";
  } else {
    return "#ff320e";
  }
};

export const round_digits = (num) => {
  return num?.toFixed(1);
};

export const define_time_zone = (range, timezone) => {
  return range.length > 1 ? 0 : -(timezone * 60);
};

export const defice_toolTip_date = (range) => {
  return range.length > 1 ? "%e %b %Y" : "%H:%M:%S";
};

export const disabledNextDay = (date) => {
  if (date.length) {
    var date_start = new Date(date[0].startDate.toString().substring(0, 16));
    var date_end = new Date(date[0].endDate.toString().substring(0, 16));
  } else {
    var date_start = new Date(date.toString().substring(0, 16));
    var date_end = new Date(date.toString().substring(0, 16));
  }
  const dayNow = `${date_start.getFullYear()}-${date_start.getMonth() + 1}-${date_start.getDate()}`;
  const dayEnd = `${date_end.getFullYear()}-${date_end.getMonth() + 1}-${date_end.getDate()}`
  const dayCompare = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;
  return dayNow != dayCompare && dayEnd != dayCompare;
};

export const dateRangeButton = (date) => {
  const dayCompare = `${new Date().getFullYear()}-${
    new Date().getMonth() + 1
  }-${new Date().getDate()}`;
  const date_start = new Date(date.toString().substring(0, 16));
  const dayNow = `${date_start.getFullYear()}-${
    date_start.getMonth() + 1
  }-${date_start.getDate()}`;
  if (dayNow != dayCompare) {
    return [dayNow];
  } else {
    return [];
  }
};

export const changeNamePluggin = (id, t) => {
  if (id == 4) return "Dormir";
  else return t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.OFF");
};

export const nameFilterAreas = (filters, func, t) => {
  if (filters.all)
    return `${t(
      "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_STATUS_FILTER.ALL"
    )} (${func.all})`;
  if (filters.any_status)
    return `${t(
      "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_STATUS_FILTER.UNKNOW_STATUS"
    )} (${func.any_state})`;
  if (filters.busy)
    return `${t(
      "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_STATUS_FILTER.BUSY"
    )} (${func.busy})`;
  if (filters.off_line)
    return `${t(
      "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_STATUS_FILTER.OFFLINE"
    )} (${func.offline})`;
  if (filters.on_line)
    return `${t(
      "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_STATUS_FILTER.AVAILABLE"
    )} (${func.free})`;
};

export const handleYaxisTempReport = (data) => {
  if (data.length == 1 && data[0].name === "ocupacion") {
    return {
      title: {
        text: "Temperatura(C°) ",
      },
      min: 0,
      max: 1,
      visible: true,
    };
  } else {
    return {
      min: 0,
      max: 60,
      title: {
        text: "Temperatura(C°) ",
      },
    };
  }
};

export const calculatedMinParameter = (dateReport, timeZone) => {
  let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
  let param = dateReport[0].startDate.toString().substring(0, 16) + "00:00:00 GMT";
  return new Date(param).getTime() - parseInt(timeZone, 10) * HORA_EN_MILISEGUNDO;
};

export const calculatedMaxParameter = (dateReport, timeZone) => {
  let day_time_end_zone =
    dateReport[0].endDate.toString().substring(0, 16) + "23:59:59 GMT" + timeZone;
  return new Date(day_time_end_zone).getTime();
};

export const roundTotalBy = (key) => {
  if (typeof key != "number") {
    const a = key.split("-");
    return `${Number(a[2])} ${MONTHS_NUMBER[Number(a[1])]} ${a[0]}`;
  } else {
    return new Date(Number(key)).toString().substring(16, 24);
  }
};

export const sumTotalBy = (data) => {
  return data
    .map((i) => i.y)
    .reduce((a, b) => a + b)
    .toFixed(1);
};

export const formatDateLocal = (dt) => {
  const aux = new Date(dt);
  const month = MONTHS_NUMBER[aux.getMonth() + 1];
  if (!month) {
    return;
  }
  return `${aux.getDate()} ${month} ${aux.getFullYear()}`;
};

export const changeColorIconBatteryLevel = (lv) => {
  if (lv <= 100 && lv > 30) {
    return "color_battery_100";
  } else if (lv <= 30 && lv > 10) {
    return "color_battery_30-10";
  } else {
    return "color_battery_10-0";
  }
};

export const getDataContry = async () => {
  const count_sa = "http://www.geonames.org/childrenJSON?geonameId=6255149";
  const count_na = "http://www.geonames.org/childrenJSON?geonameId=6255150";
  const countries_sa = await fetch(count_sa);
  const countries_na = await fetch(count_na);
  const result_sa = await countries_sa.json();
  const result_na = await countries_na.json();
  const countries = result_na.geonames.concat(result_sa.geonames);
  return countries;
};

export const getStates = async (country) => {
  const url = `http://www.geonames.org/childrenJSON?geonameId=${country}`;
  const result = await fetch(url);
  const department = await result.json();
  return department;
};

export const getCities = async (department) => {
  const url = `http://www.geonames.org/childrenJSON?geonameId=${department}`;
  const result = await fetch(url);
  const city = await result.json();
  return city;
};

export const getUTC = async (lat, lng) => {
  const url = `https://timezone.abstractapi.com/v1/current_time/?api_key=b75b7ed833ca43bfaf616f0938ccd86a&location=${lat},${lng}`;
  const utf = await fetch(url);
  const resultuft = await utf.json();
  return resultuft;
};

export const testdeviseFails = () => {
  const names = [
    "sensor_de_movimiento",
    "interruptor",
    "termostato",
    "sensor_puerta",
    "bloqueo_de_puerta",
    "medidor_de_energia",
  ];

  const handleDevices = () => {
    const x = [];
    const randomNum = Number(Math.random() * 3).toFixed(0);
    for (let index = 0; index <= randomNum; index++) {
      const randomNamesDesc = Number(Math.random() * 5).toFixed(0);
      const randomNamesDate = Number(Math.random() * 20).toFixed(0);
      x.push({
        alias: names[randomNamesDesc],
        name: names[randomNamesDesc].replace(/_/g, " "),
        date: Number(randomNamesDate),
      });
    }
    return x;
  };

  const resutl = [];
  for (let index = 0; index < 10; index++) {
    const randomName = Number(Math.random() * 10000).toFixed(0);
    resutl.push({
      hab: Number(randomName),
      devices: handleDevices(),
    });
  }
  return resutl;
};

export const convertDateInFailsDevices = (dt) => {
  return formatDateLocal(Number(dt));
};

export const statePluginMotelConvert = (state, t) => {
  if (state === "" || state === "-2")
    return {
      bg: "#FFFFFF",
      text: t("VIEW_AREA.COMPONENT_MOTEL_PLUGIN_CARD.NO_AVAILABLE"),
      border: "#000000",
      color: "#000000",
    };
  const states = {
    0: {
      bg: "#00FF00",
      text: t("VIEW_AREA.COMPONENT_MOTEL_PLUGIN_CARD.AVAILABLE"),
      border: "#85A2AA",
      color: "#000000",
    },
    25: {
      bg: "#FF00FF",
      text: t("VIEW_AREA.COMPONENT_MOTEL_PLUGIN_CARD.DIRTY"),
      border: "",
      color: "#FFF",
    },
    50: {
      bg: "#0000FF",
      text: t("VIEW_AREA.COMPONENT_MOTEL_PLUGIN_CARD.SOLD"),
      border: "",
      color: "#FFF",
    },
    75: {
      bg: "#FF00FF",
      text: t("VIEW_AREA.COMPONENT_MOTEL_PLUGIN_CARD.CLEANING"),
      border: "",
      color: "#FFF",
    },
    100: {
      bg: "#FF0000",
      text: t("VIEW_AREA.COMPONENT_MOTEL_PLUGIN_CARD.BLOCKED"),
      border: "",
      color: "#FFF",
    },
    125: {
      bg: "#00FFFF",
      text: t("VIEW_AREA.COMPONENT_MOTEL_PLUGIN_CARD.BOOKED"),
      border: "",
      color: "#FFF",
    },
  };

  return states[Number(state)];
};

export const timeConvertMacromedidor = () => {
  const fechaHoy = new Date();
  const year = fechaHoy.getFullYear().toString();
  const dia = fechaHoy.getDate().toString();
  const nDia = (fechaHoy.getDate() + 1).toString();
  const mesActual = fechaHoy.getMonth() + 1;
  const fechaI = `${year}-${mesActual}-${dia} 00:00:00`;
  const fechaIF = `${year}-${mesActual}-${nDia} 00:01:00`;
  const fI = Date.parse(fechaI);
  const fIF = Date.parse(fechaIF);
  const start_date = parseInt(fI.toString().substring(0, 10));
  const end_date = start_date + 86460;
  const end_date_new = start_date + 86399;
  return {
    startTime: start_date,
    endTime: end_date,
    year: parseInt(year),
    endTimeNew: end_date_new,
    month: mesActual,
  };
};

export const convertedTimeEditProject = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear().toString();
  const day = currentDate.getDate().toString();
  const currentMonth = currentDate.getMonth() + 1;
  const dateI = `${year}-${currentMonth}-${day}`;
  return dateI;
};

export const actualDatezero = () => {
  const fechaHoy = new Date();
  const year = fechaHoy.getFullYear().toString();
  const mesActual = fechaHoy.getMonth() + 1;
  const dia = fechaHoy.getDate().toString();
  const hora = fechaHoy.getHours();
  const minute = fechaHoy.getMinutes();
  const seg = fechaHoy.getSeconds();
  const fechaI = `${year}-${mesActual}-${dia} ${hora}:${minute}:${seg}`;
  const fI = Date.parse(fechaI);
  const fit = parseInt(fI.toString().substring(0, 10));

  return {
    startTime: fit,
    startTimetwo: fI,
    endTime: fI - 3600000,
    month: mesActual,
    year: year,
    actual: fI,
  };
};

export const statePluginMotel = (state, t) => {
  const STATES = [
    {
      key: 0,
      name: t("VIEW_AREA.COMPONENT_MOTEL_PLUGIN_CARD.AVAILABLE"),
      style: "libre_motel_plugin",
    },
    {
      key: 25,
      name: t("VIEW_AREA.COMPONENT_MOTEL_PLUGIN_CARD.DIRTY"),
      style: "sucia_motel_plugin",
    },
    {
      key: 50,
      name: t("VIEW_AREA.COMPONENT_MOTEL_PLUGIN_CARD.SOLD"),
      style: "vendida_motel_plugin",
    },
    {
      key: 75,
      name: t("VIEW_AREA.COMPONENT_MOTEL_PLUGIN_CARD.CLEANING"),
      style: "aseo_motel_plugin",
    },
    {
      key: 100,
      name: t("VIEW_AREA.COMPONENT_MOTEL_PLUGIN_CARD.BLOCKED"),
      style: "bloqueada_motel_plugin",
    },
    {
      key: 125,
      name: t("VIEW_AREA.COMPONENT_MOTEL_PLUGIN_CARD.BOOKED"),
      style: "reservada_motel_plugin",
    },
  ];
  if (state == 25 || state == 75) {
    return STATES.filter((i) => i.key != 75).filter((i) => i.key != 25);
  }

  return STATES.filter((i) => i.key != state).filter((i) => i.key != 75);
};

export const statePluginbathroom = () => {
  const STATES = [
    { id_device: 26126, name: "PAPEL" },
    { id_device: 26128, name: "JABÓN" },
    { id_device: 26127, name: "BAÑ0" },
  ];

  return STATES;
};

export const FindSerialPluginbathroom = (data) => {
  if (
    !data ||
    !data.data ||
    !data.data.Device ||
    !data.data.Controller ||
    !data.data.Controller.serial
  ) {
    return null;
  }
  let foundCategory = false;
  for (let device of data.data.Device) {
    if (
      device.Category &&
      device.Category.sub_category === 6 &&
      device.Category.category === 600
    ) {
      foundCategory = true;
      break;
    }
  }
  if (!foundCategory) {
    return null;
  }
  let statePlugin = data.data.Device.filter(
    (device) =>
      device.visible === true &&
      device.name !== "Plugin Baños" &&
      device.name !== "Plugin Baños IBM"
  );

  return {
    serial: data.data.Controller.serial,
    statePlugin: statePlugin,
  };
};

export const CheckStatusPluginBathroom = (dataArray) => {
  if (!dataArray) {
    return false;
  }
  return dataArray.some((device) => device.variables.Status === "1");
};

export const filterByPluggin = (data) => {
  const pluggin = data?.data?.Device?.find(
    (i) =>
      (i?.Category?.category == 600 &&
        i?.visible &&
        (i?.Category?.sub_category == 1 ||
          i?.Category?.sub_category == 6 ||
          i?.Category?.sub_category == 3 ||
          i?.Category?.sub_category == 9)) ||
      (i?.Category?.category == "generic_io" &&
        i?.visible &&
        i?.Category?.sub_category == "generic_io")
  );

  if (!pluggin) return "__status_null_area";
  if (
    (pluggin?.Category?.category == 600 || pluggin?.Category?.category == "generic_io") &&
    pluggin?.visible &&
    (pluggin?.Category?.sub_category == 1 ||
      pluggin?.Category?.sub_category == "generic_io")
  ) {
    if (pluggin?.variables?.EstadoTexto == "Libre") {
      return "__status_libre_area";
    } else if (pluggin?.variables?.EstadoTexto == "Ocupado") {
      return "__status_ocupado_area";
    } else {
      return "__status_null_area";
    }
  } else if (
    pluggin.Category.category == 600 &&
    pluggin.visible &&
    (pluggin.Category.sub_category == 6 || pluggin.Category.sub_category == 3)
  ) {
    if (pluggin?.variables.Estado == "" || pluggin?.variables.Estado === "-2")
      return "sin_estado_motel_plugin";
    const status_motel = {
      0: "libre_motel_plugin",
      25: "sucia_motel_plugin",
      50: "vendida_motel_plugin",
      75: "aseo_motel_plugin",
      100: "bloqueada_motel_plugin",
      125: "reservada_motel_plugin",
    };
    return status_motel[pluggin?.variables.Estado];
  }
  if (
    pluggin?.Category?.category == 600 &&
    pluggin?.visible &&
    pluggin?.Category?.sub_category == 9
  ) {
    if (pluggin?.variables?.EstadoTexto == "Libre") {
      return "__status_libre_area";
    } else if (pluggin?.variables?.EstadoTexto == "Ocupado") {
      return "__status_ocupado_area";
    } else {
      return "__status_null_area";
    }
  }
};

export function percentage_total(value) {
  let result;
  if (value) {
    if (value?.length < 2) {
      result = (parseInt(value) / 10) * 100;
    } else if (value.length < 3) {
      result = (parseInt(value) / 100) * 100;
    } else if (value.length < 4) {
      result = (parseInt(value) / 1000) * 100;
    } else if (value.length < 5) {
      result = (parseInt(value) / 10000) * 100;
    } else if (value.length < 6) {
      result = (parseInt(value) / 100000) * 100;
    } else if (value.length < 7) {
      result = (parseInt(value) / 1000000) * 100;
    } else if (value.length < 8) {
      result = (parseInt(value) / 10000000) * 100;
    } else if (value.length < 9) {
      result = (parseInt(value) / 100000000) * 100;
    } else if (value.length < 10) {
      result = (parseInt(value) / 1000000000) * 100;
    }
    return result;
  } else {
    return (result = 0);
  }
}

export const fncDatePerDay = (date = null) => {
  if (!date) {
    const equalNow = new Date().toString().substring(0, 16) + "00:00:00 GMT";
    const equalDate = new Date(date).toString().substring(0, 16) + "00:00:00 GMT";
    const dateStartNow = new Date().toString().substring(0, 16) + "00:00:00 GMT";
    const dateEndNow = new Date().toString().substring(0, 16) + "23:59:59 GMT";
    const start = new Date(dateStartNow).getTime();
    const end = new Date(dateEndNow).getTime();
    const equal = new Date(equalNow).getTime() != new Date(equalDate).getTime();
    return {
      start,
      end,
      equal,
    };
  } else {
    const equalNow = new Date().toString().substring(0, 16) + "00:00:00 GMT";
    const equalDate = new Date(date).toString().substring(0, 16) + "00:00:00 GMT";
    const dateStartNow = new Date(date).toString().substring(0, 16) + "00:00:00 GMT";
    const dateEndNow = new Date(date).toString().substring(0, 16) + "23:59:59 GMT";
    const start = new Date(dateStartNow).getTime();
    const end = new Date(dateEndNow).getTime();
    const equal = new Date(equalNow).getTime() != new Date(equalDate).getTime();
    return {
      start,
      end,
      equal,
    };
  }
};

/**
 * @param {string} localStorageKey - La clave del localStorage que se va a preservar.
 */
export const ClearLocalStorageExcept = (localStorageKey) => {
  var valorAEvitar = localStorage.getItem(localStorageKey);
  localStorage.clear();
  localStorage.setItem(localStorageKey, valorAEvitar);
};

export const ExtractTypeGateway = () => {
  const location = useLocation();
  if (location) {
    const numbersplit = location.pathname.indexOf("-");
    const typeGateway = location.pathname.substring(numbersplit + 1);
    return typeGateway;
  }
};
