import React, { useEffect, useState, useContext } from "react";
import { Devices } from "../../constants/devices_types";
import { AuthContext } from "../../context/AuthProvider";
import Tooltip from "@mui/material/Tooltip";
import Loading_devices from "../loading/Loading_devices";
import Swal from "sweetalert2";
//multilanguage
import { useTranslation } from "react-i18next";

const HSC = ({ item, send }) => {
  const [libre, setLibre] = useState(false);
  const [manual, setManual] = useState(false);
  const [apagado, setApagado] = useState(false);
  const [auto, setAuto] = useState(false);
  const [load, setLoad] = React.useState(false);
  const { t } = useTranslation();
  const { successMessage, errorMessage, permissionsRoleUser } =
    useContext(AuthContext);
  useEffect(() => {
    switch (item.variables.Modo) {
      case "Manual":
        setManual(true);
        setApagado(false);
        setAuto(false);
        break;
      case "Apagar":
        setManual(false);
        setApagado(true);
        setAuto(false);
        break;
      case "Apagado":
        setManual(false);
        setApagado(true);
        setAuto(false);
        break;
      case "Auto":
        setManual(false);
        setApagado(false);
        setAuto(true);
        break;
      default:
        return false;
    }
    setLibre(item?.variables?.EstadoTexto === "Libre" ? true : false);
  }, [item]);

  useEffect(() => {
    setLoad(false);
  }, [errorMessage, successMessage]);

  async function action(event) {
    await Swal.fire({
      title: t("VIEW_AREA.COMPONENT_SWITCH_ALERT_CARD.TITLE"),
      text: t("VIEW_AREA.COMPONENT_SWITCH_ALERT_CARD.TEXT"),
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t(
        "VIEW_AREA.COMPONENT_SWITCH_ALERT_CARD.CONFIRMBUTTONTEXT"
      ),
    }).then((result) => {
      if (result.isConfirmed) {
        setLoad(true);
        switch (event) {
          case 1:
            if (!libre) {
              call("Libre", 1);
              setLibre(!libre);
            }
            break;
          case 2:
            if (libre) {
              call("Ocupado", 2);
              setLibre(false);
            }
            break;
          case 3:
            if (!manual) {
              setManual(true);
              setApagado(false);
              setAuto(false);
              call("Manual", 3);
            }
            break;
          case 4:
            if (!apagado) {
              setManual(false);
              setApagado(true);
              setAuto(false);
              call("Apagar", 4);
            }
            break;
          case 5:
            if (!auto) {
              setManual(false);
              setApagado(false);
              setAuto(true);
              call("Auto", 5);
            }
            break;
          default:
        }
      }
    });
  }

  function call(event, action) {
    return new Promise(() => {
      setTimeout(() => {
        send(item, event, action, item.Category.sub_category);
      }, 2000);
    });
  }

  const translationAccionTexto = (accionTexto) => {
    const systemStatus = typeof accionTexto === "string" ? accionTexto : "";
    if (systemStatus?.toLowerCase() === "libre") {
      return t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.AVAILABLE");
    } else if (systemStatus?.toLowerCase() === "scan") {
      return t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.SCAN");
    } else if (systemStatus?.toLowerCase() === "puerta abierta") {
      return t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.OPEN_DOOR");
    } else if (systemStatus?.toLowerCase() === "ocupado") {
      return t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.BUSY");
    } else {
      return systemStatus;
    }
  };

  const translatingPluginTitle = () => {
    const pluginName = typeof item?.name == "string" ? item.name : "";
    if (
      pluginName?.toLowerCase() === "plugin ocupacion" ||
      pluginName?.toLowerCase() === "horus ocupacion"
    ) {
      return t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.PLUGIN_OCCUPATION");
    } else {
      return pluginName;
    }
  };

  return (
    <div className="_hsc_container">
      <div className="_hsc_container_title_status">
        <div class="_hsc__title">
          <span>{translatingPluginTitle()}</span>
        </div>
        <div className="_hsc_status">
          <span className="__icon_wifi_hsc">
            <i class="fas fa-wifi"></i>
          </span>
        </div>
      </div>

      {/* status home */}
      <div className={`_hsc_ocupation ${load ? "_loading_hsc_other" : false}`}>
        <div className="_hsc_home_img">
          <span>
            <img
              src={libre ? Devices.DESOCUPADA : Devices.OCUPADA}
              alt="home"
              width="70"
              height="70"
            />
          </span>
        </div>
        <div className="_hsc_home_buttons">
          <div
            className="_free_container"
            onClick={() => {
              action(1);
            }}
          >
            <div className={libre ? "__free_bg" : "__free"}>
              {t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.AVAILABLE")}
            </div>
          </div>

          <Tooltip
            title={t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.BUSY_TOOLTIP")}
          >
            <div
              className="_busy_container"
              onClick={() => {
                action(2);
              }}
            >
              <div className={!libre ? "__busy_bg" : "__busy"}>
                {t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.BUSY")}
              </div>
            </div>
          </Tooltip>
        </div>
      </div>

      <div className="__loading_hsc">{load ? <Loading_devices /> : false}</div>

      {/* status system */}
      <div
        className={`_hsc_status_system ${load ? "_loading_hsc_other" : false}`}
      >
        <div className="_hsc_status_system_title">
          <span>
            {t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.SYSTEM_STATUS")}
          </span>
        </div>
        <>
          <div
            className={libre ? "__status_system_free" : "__status_system_busy"}
          >
            <span className="__status_word">
              {translationAccionTexto(item?.variables?.AccionTexto)}
            </span>
          </div>
        </>
      </div>

      {/* mode */}
      <div className={`_hsc_mode ${load ? "_loading_hsc_other" : false}`}>
        <div className="_hsc_mode_title">
          <span>{t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.MODE")}</span>
        </div>
        <div className="__mode_container">
          <span
            className={manual ? "__mode_item_manual_bg" : "__mode_item_manual"}
            onClick={() => {
              action(3);
            }}
          >
            {t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.MANUAL")}
          </span>
          <span
            className={apagado ? "__mode_item_off_bg" : "__mode_item_off"}
            onClick={() => {
              action(4);
            }}
          >
            {t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.OFF")}
          </span>
          <span
            className={auto ? "__mode_item_auto_bg" : "__mode_item_auto"}
            onClick={() => {
              action(5);
            }}
          >
            {t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.AUTO")}
          </span>
        </div>
      </div>
    </div>
  );
};
export default HSC;
