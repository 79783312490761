import styled, { keyframes } from "styled-components";

const shakeVertical = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  10%, 30%, 50%, 70% {
    transform: translateY(-8px);
  }
  20%, 40%, 60% {
    transform: translateY(8px);
  }
  80% {
    transform: translateY(6.4px);
  }
  90% {
    transform: translateY(-6.4px);
  }
`;

export const WrapperForm = styled.div`
  width: 100%;
  display: flex;
  justify-self: center;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 650px) {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
  }
`;

export const WrapperIndication = styled.div`
  display: flex;
  flex-direction: column;
  cursor: text;
  gap: 4px;
  p {
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    color: #000000;
    font-size: 17px;
  }
`;

export const Validations = styled.div`
  display: flex;
  flex-direction: column;
  cursor: text;

  div {
    gap: 10px;
    font-family: Montserrat, sans-serif;
    color: #000000;
    font-size: 14px;
    display: flex;
    align-items: center;
    span {
      gap: 20px;
      background-color: ${({ background }) => background || "#847F7F"};
      border-radius: 50%;
      width: 10px;
      height: 10px;
      display: inline-block;
      line-height: 20px;
      text-align: center;
      margin-right: 5px;
      color: rgba(255, 255, 255, 0);
    }
  }
`;

export const WrapperInputsButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const Button = styled.button`
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 14px;
  background-color: ${(props) => (props.error ? "#F2B0B0" : "#B2E5B7")};
  border: ${(props) => (props.error ? "solid #EF0202" : "solid #67A46D")};
  width: 270px;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  &:hover {
    background-color: ${(props) => (props.error ? "#8ACD8C" : "#8ACD8C")};
  }

  &.shake {
    animation: ${shakeVertical} 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955)
      both;
  }

  animation: ${(props) =>
    props.animate
      ? `${shakeVertical} 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both`
      : "none"};
`;

export const WrapperAlert = styled.div`
  position: fixed;
  margin-bottom: -500px;
`;
