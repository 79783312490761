import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { GET_DATA_REPORT } from "../../controllers/reportController";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import frLocale from "date-fns/locale/es";
import { green } from "@material-ui/core/colors";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import Loading from "../loading/Loading";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { AuthContext } from "../../context/AuthProvider";
import grafica from "../../assets/grafica_vacia.svg";
import Construction from "../loading/Construction";
import html2canvas from "html2canvas";
import OccupancyChart from "./OccupancyChart";

//Functions
import { formatDateLocal } from "../../utils/helpers";

//query and mutation
import { PDF_OCUPATION_BY_ROOM } from "../../controllers/pdfControllers";

//multilanguage
import { useTranslation } from "react-i18next";

// Map(Highcharts);
Highcharts.setOptions({
  lang: {
    resetZoom: "Quitar zoom",
    resetZoomTitle: "Quita todo el zoom aplicado",
  },
});

const innerTheme = createTheme({
  palette: {
    primary: {
      main: green[500],
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#4caf50e6",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableCell2 = withStyles((theme) => ({
  head: {
    backgroundColor: "#4caf50e6",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    fontWeight: "bold",
  },
}))(TableCell);
const StyledTableCell3 = withStyles((theme) => ({
  head: {
    backgroundColor: "#4caf50e6",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    color: "red",
    fontWeight: "bold",
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#22a374",
    position: "relative",
  },
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  graphic: {
    marginTop: 30,
  },
  image: {
    position: "relative",
    height: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
  table: {
    minWidth: 700,
  },

  info: {
    marginBottom: 60,
  },
  tab: {
    marginTop: 60,
  },
}));

const Occupation = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [pdfGenerator] = useMutation(PDF_OCUPATION_BY_ROOM);
  const {
    areas,
    setArea,
    area,
    setIdArea,
    idArea,
    roomId,
    setRoomId,
    graphic,
    setGraphic,
    setLoadingPdf,
  } = useContext(AuthContext);
  const { project, RoomId, generalPdf, open, setOpen } = props;
  const [reportData, setReportData] = useState([]);
  const [dateMin, setDateMin] = useState();
  const [dateMax, setDateMax] = useState();
  const [parametroMin, setParametroMin] = useState();
  const [parametroMax, setParametroMax] = useState();
  const [nextDay, setNextDay] = useState(true);
  const [paramDate, setParamDate] = useState("");
  const [dayMilisegundos, setDayMilisegundos] = useState(0);
  const [time, setTime] = useState(project.time_zone * 60);
  const classes = useStyles();
  const [room, setRoom] = React.useState(localStorage.getItem("RoomName"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [changeDay, setChangeDay] = React.useState(false);
  const printRef = React.useRef();
  const [basePdf, setBasePdf] = React.useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (event) => {
    // setRoom(event.target.value);
    //setArea(event.target.value.alias);
    setReportData([]);

    setIdArea(event.target.value);
    getDataReport({
      variables: {
        project_id: parseInt(project.id, 10),
        RoomId: parseInt(event.target.value, 10),
        event_date: paramDate,
        start_time: parametroMin,
        end_time: parametroMax,
      },
      fetchPolicy: "cache-and-network",
    });
    // setRoomId(event.target.value);
  };
  const [selectedDate, setSelectedDate] = React.useState();

  const [getDataReport, { data, loading, error, networkStatus, refetch }] =
    useLazyQuery(GET_DATA_REPORT, {
      fetchPolicy: "no-cache",
    });

  // const _projects = () => {
  //   const project = localStorage.getItem("project");
  //   const id_project = localStorage.getItem("id");
  //   return JSON.parse(project).filter((key) => key.id == id_project);
  // };

  useEffect(() => {
    if (graphic) {
      dataPdf();
    }
  }, [graphic]);

  useEffect(() => {
    if (basePdf) {
      decodePdf();
    }
  }, [basePdf]);

  useEffect(() => {
    if (data) {
      setReportData(data);
      setChangeDay(true);
    }
  }, [data]);

  useEffect(() => {
    //  Si el proyecto no tiene zona horaria
    if (!project.start_time || !project.end_time) {
      let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
      //Obtenemos la fecha actual
      var f = new Date();
      //La configuramos para mostrarla en el calendario
      setSelectedDate(
        new Date(f.getFullYear() + "-" + (f.getMonth() + 1) + "-" + f.getDate())
      );
      setParamDate(
        f.getFullYear() + "-" + (f.getMonth() + 1) + "-" + f.getDate()
      );
      //Guardamos el dia actual en milisegundos
      setDayMilisegundos(
        new Date(
          f.getFullYear() + "-" + (f.getMonth() + 1) + "-" + f.getDate()
        ).getTime()
      );
      var day = new Date();
      // Armamos la hora inicial del proyecto + time zone con el que está configurado
      var day_time_zone =
        day.toString().substring(0, 16) + " 00:00:00 GMT" + project.time_zone;
      // Configuramos el parametro que le enviaremos a la query del backend, en formato GMT 0 con la hora inicial
      var param = day.toString().substring(0, 16) + " 00:00:00 GMT";
      //Parámetro en milisegundos
      var tempPara =
        new Date(param).getTime() -
        parseInt(project.time_zone, 10) * HORA_EN_MILISEGUNDO;
      //fecha en milisegundos
      var tmpDate = new Date(day_time_zone).getTime();
      //Parámetro inicial
      setParametroMin(tempPara);
      //Fecha inicial
      setDateMin(tmpDate);
      //parámetro final
      setParametroMax(parseInt(tmpDate, 10) + 86400000 - 1000);
      //Fecha final
      setDateMax(parseInt(tmpDate, 10) + 86400000 - 1000);
      getDataReport({
        variables: {
          project_id: parseInt(project.id, 10),
          RoomId: parseInt(idArea, 10),
          event_date:
            f.getFullYear() + "-" + (f.getMonth() + 1) + "-" + f.getDate(),
          start_time: tempPara,
          end_time: parseInt(tmpDate, 10) + 86400000 - 1000,
        },
        fetchPolicy: "cache-and-network",
      });
    } else {
      var f = new Date();
      //Valor de un día en milisegundos.
      let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
      //Valor de una hora en milisegundos
      let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
      var date = new Date();
      //Fecha y hora teniendo en cuenta el time_zone del proyecto
      var day2 =
        date.toString().substring(0, 16) +
        " " +
        project.start_time +
        " GMT " +
        project.time_zone;

      //Fecha y hora inicial del proyecto en GMT
      var param2 = day2.substring(0, 16) + " " + project.start_time + " GMT";
      //Fecha y hora inicial en GMT a milisegundos.
      var tempPara =
        new Date(param2).getTime() -
        parseInt(project.time_zone, 10) * HORA_EN_MILISEGUNDO;
      //Fecha y hora inicial en GMT -time_zone a milisegundos.
      var tmpDate = new Date(day2).getTime();
      //Verificar si el día hotelero no ha terminado
      if (f.getTime() < tmpDate) {
        setParametroMin(parseInt(tempPara) - DIA_EN_MILISEGUNDOS);
        setDateMin(parseInt(tmpDate) - DIA_EN_MILISEGUNDOS);
        setParametroMax(
          parseInt(tempPara, 10) + 86400000 - 1000 - DIA_EN_MILISEGUNDOS
        );
        setDateMax(
          parseInt(tmpDate, 10) + 86400000 - 1000 - DIA_EN_MILISEGUNDOS
        );

        var paramteroDate = new Date(f.getTime() - DIA_EN_MILISEGUNDOS);
        setParamDate(
          paramteroDate.getFullYear() +
            "-" +
            (paramteroDate.getMonth() + 1) +
            "-" +
            paramteroDate.getDate()
        );
        setSelectedDate(new Date(f.getTime() - DIA_EN_MILISEGUNDOS));

        setDayMilisegundos(f.getTime() - DIA_EN_MILISEGUNDOS);
        getDataReport({
          variables: {
            project_id: parseInt(project.id, 10),
            RoomId: parseInt(idArea, 10),
            event_date:
              paramteroDate.getFullYear() +
              "-" +
              (paramteroDate.getMonth() + 1) +
              "-" +
              paramteroDate.getDate(),
            start_time: parseInt(tempPara) - DIA_EN_MILISEGUNDOS,
            end_time:
              parseInt(tempPara, 10) + 86400000 - 1000 - DIA_EN_MILISEGUNDOS,
          },
          fetchPolicy: "cache-and-network",
        });
      } else {
        setParametroMin(parseInt(tempPara));
        setDateMin(parseInt(tmpDate));
        setParametroMax(parseInt(tempPara, 10) + 86400000 - 1000);
        setDateMax(parseInt(tmpDate, 10) + 86400000 - 1000);
        setSelectedDate(new Date(f.getTime()));
        var paramteroDate = new Date(f.getTime());
        setParamDate(
          paramteroDate.getFullYear() +
            "-" +
            (paramteroDate.getMonth() + 1) +
            "-" +
            paramteroDate.getDate()
        );
        setDayMilisegundos(f.getTime());
        getDataReport({
          variables: {
            project_id: parseInt(project.id, 10),
            RoomId: parseInt(idArea, 10),
            event_date:
              paramteroDate.getFullYear() +
              "-" +
              (paramteroDate.getMonth() + 1) +
              "-" +
              paramteroDate.getDate(),
            start_time: parseInt(tempPara),
            end_time: parseInt(tempPara, 10) + 86400000 - 1000,
          },
          fetchPolicy: "cache-and-network",
        });
      }
    }
  }, []);

  useImperativeHandle(ref, () => ({
    async occupationPdf() {
      setLoadingPdf(true);
      await imageGraphic();
    },
  }));

  /* const { data, loading, error, networkStatus, refetch } = useQuery(
    GET_DATA_REPORT,
    {
      variables: {
        project_id: parseInt(project.id, 10),
        RoomId: parseInt(idArea, 10),
        event_date: paramDate,
        start_time: parametroMin,
        end_time: parametroMax,
      },
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
    }
  );*/

  const before = (date) => {
    setChangeDay(false);
    setReportData([]);
    setNextDay(false);
    let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
    let date2 = date.getTime();
    setSelectedDate(new Date(date2 - DIA_EN_MILISEGUNDOS));
    var paramteroDate = new Date(date2 - DIA_EN_MILISEGUNDOS);
    setParamDate(
      paramteroDate.getFullYear() +
        "-" +
        (paramteroDate.getMonth() + 1) +
        "-" +
        paramteroDate.getDate()
    );
    if (!project.start_time || !project.end_time) {
      var dayTemp = new Date(date2 - DIA_EN_MILISEGUNDOS);
      var day2 =
        dayTemp.toString().substring(0, 16) +
        " 00:00:00 GMT " +
        project.time_zone;
      var param2 = day2.substring(0, 16) + " 00:00:00 GMT";

      var tempPara =
        new Date(param2).getTime() -
        parseInt(project.time_zone, 10) * HORA_EN_MILISEGUNDO;
      var tmpDate = new Date(day2).getTime();
      setParametroMin(parseInt(tempPara));
      setDateMin(parseInt(tmpDate));
      setParametroMax(parseInt(tempPara, 10) + 86400000 - 1000);
      setDateMax(parseInt(tmpDate, 10) + 86400000 - 1000);
      getDataReport({
        variables: {
          project_id: parseInt(project.id, 10),
          RoomId: parseInt(idArea, 10),
          event_date:
            paramteroDate.getFullYear() +
            "-" +
            (paramteroDate.getMonth() + 1) +
            "-" +
            paramteroDate.getDate(),
          start_time: parseInt(tempPara),
          end_time: parseInt(tempPara, 10) + 86400000 - 1000,
        },
        fetchPolicy: "cache-and-network",
      });
    } else {
      var dayTemp = new Date(date2 - DIA_EN_MILISEGUNDOS);
      var day2 =
        dayTemp.toString().substring(0, 16) +
        " " +
        project.start_time +
        " GMT " +
        project.time_zone;
      var param2 = day2.substring(0, 16) + " " + project.start_time + " GMT";

      var tempPara =
        new Date(param2).getTime() -
        parseInt(project.time_zone, 10) * HORA_EN_MILISEGUNDO;
      var tmpDate = new Date(day2).getTime();

      setParametroMin(parseInt(tempPara));
      setDateMin(parseInt(tmpDate));
      setParametroMax(parseInt(tempPara, 10) + 86400000 - 1000);
      setDateMax(parseInt(tmpDate, 10) + 86400000 - 1000);
      getDataReport({
        variables: {
          project_id: parseInt(project.id, 10),
          RoomId: parseInt(idArea, 10),
          event_date:
            paramteroDate.getFullYear() +
            "-" +
            (paramteroDate.getMonth() + 1) +
            "-" +
            paramteroDate.getDate(),
          start_time: parseInt(tempPara),
          end_time: parseInt(tempPara, 10) + 86400000 - 1000,
        },
        fetchPolicy: "cache-and-network",
      });
    }
  };

  const after = (date) => {
    setChangeDay(false);
    setReportData([]);
    let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
    let date2 = date.getTime() + DIA_EN_MILISEGUNDOS;

    if (date2 <= dayMilisegundos) {
      setSelectedDate(new Date(date2));
      var paramteroDate = new Date(date2);
      setParamDate(
        paramteroDate.getFullYear() +
          "-" +
          (paramteroDate.getMonth() + 1) +
          "-" +
          paramteroDate.getDate()
      );
      let dateNext = date2 + DIA_EN_MILISEGUNDOS;
      if (dateNext > dayMilisegundos) {
        setNextDay(true);
      }

      if (!project.start_time || !project.end_time) {
        var dayTemp = new Date(date2);
        var day2 =
          dayTemp.toString().substring(0, 16) +
          " 00:00:00 GMT " +
          project.time_zone;
        var param2 = day2.substring(0, 16) + " 00:00:00 GMT";

        var tempPara =
          new Date(param2).getTime() -
          parseInt(project.time_zone, 10) * HORA_EN_MILISEGUNDO;
        var tmpDate = new Date(day2).getTime();

        setParametroMin(parseInt(tempPara));
        setDateMin(parseInt(tmpDate));
        setParametroMax(parseInt(tempPara, 10) + 86400000 - 1000);
        setDateMax(parseInt(tmpDate, 10) + 86400000 - 1000);
        getDataReport({
          variables: {
            project_id: parseInt(project.id, 10),
            RoomId: parseInt(idArea, 10),
            event_date:
              paramteroDate.getFullYear() +
              "-" +
              (paramteroDate.getMonth() + 1) +
              "-" +
              paramteroDate.getDate(),
            start_time: parseInt(tempPara),
            end_time: parseInt(tempPara, 10) + 86400000 - 1000,
          },
          fetchPolicy: "cache-and-network",
        });
      } else {
        var dayTemp = new Date(date2);
        var day2 =
          dayTemp.toString().substring(0, 16) +
          " " +
          project.start_time +
          " GMT " +
          project.time_zone;
        var param2 = day2.substring(0, 16) + " " + project.start_time + " GMT";

        var tempPara =
          new Date(param2).getTime() -
          parseInt(project.time_zone, 10) * HORA_EN_MILISEGUNDO;
        var tmpDate = new Date(day2).getTime();

        setParametroMin(parseInt(tempPara));
        setDateMin(parseInt(tmpDate));
        setParametroMax(parseInt(tempPara, 10) + 86400000 - 1000);
        setDateMax(parseInt(tmpDate, 10) + 86400000 - 1000);
        getDataReport({
          variables: {
            project_id: parseInt(project.id, 10),
            RoomId: parseInt(idArea, 10),
            event_date:
              paramteroDate.getFullYear() +
              "-" +
              (paramteroDate.getMonth() + 1) +
              "-" +
              paramteroDate.getDate(),
            start_time: parseInt(tempPara),
            end_time: parseInt(tempPara, 10) + 86400000 - 1000,
          },
          fetchPolicy: "cache-and-network",
        });
      }
    } else {
      setNextDay(true);
    }
  };

  const handleDateChange = (date) => {
    setChangeDay(false);
    setReportData([]);
    let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;

    setSelectedDate(date);

    if (!project.start_time || !project.end_time) {
      var day2 =
        date.toString().substring(0, 16) + " 00:00:00 GMT " + project.time_zone;
      var param2 = day2.substring(0, 16) + " 00:00:00 GMT";
      var tempPara =
        new Date(param2).getTime() -
        parseInt(project.time_zone, 10) * HORA_EN_MILISEGUNDO;
      var tmpDate = new Date(day2).getTime();

      if (tmpDate > dayMilisegundos) {
        setNextDay(true);
      } else {
        setNextDay(false);
      }
      setParametroMin(parseInt(tempPara));
      setDateMin(parseInt(tmpDate));
      setParametroMax(parseInt(tempPara, 10) + 86400000 - 1000);
      setDateMax(parseInt(tmpDate, 10) + 86400000 - 1000);

      getDataReport({
        variables: {
          project_id: parseInt(project.id, 10),
          RoomId: parseInt(idArea, 10),
          event_date:
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate(),
          start_time: parseInt(tempPara),
          end_time: parseInt(tempPara, 10) + 86400000 - 1000,
        },
        fetchPolicy: "cache-and-network",
      });
    } else {
      var day2 =
        date.toString().substring(0, 16) +
        " " +
        project.start_time +
        " GMT " +
        project.time_zone;
      var param2 = day2.substring(0, 16) + " " + project.start_time + " GMT";

      var tempPara =
        new Date(param2).getTime() -
        parseInt(project.time_zone, 10) * HORA_EN_MILISEGUNDO;
      var tmpDate = new Date(day2).getTime();
      if (tmpDate > dayMilisegundos) {
        setNextDay(true);
      } else {
        setNextDay(false);
      }

      setParametroMin(parseInt(tempPara));
      setDateMin(parseInt(tmpDate));
      setParametroMax(parseInt(tempPara, 10) + 86400000 - 1000);
      setDateMax(parseInt(tmpDate, 10) + 86400000 - 1000);

      getDataReport({
        variables: {
          project_id: parseInt(project.id, 10),
          RoomId: parseInt(idArea, 10),
          event_date:
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate(),
          start_time: parseInt(tempPara),
          end_time: parseInt(tempPara, 10) + 86400000 - 1000,
        },
        fetchPolicy: "cache-and-network",
      });
    }
    //refetch();
  };

  function msToTime(duration) {
    var seconds = parseInt((duration / 1000) % 60),
      minutes = parseInt((duration / (1000 * 60)) % 60),
      hours = parseInt((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes;
  }

  function round(num, decimales = 1) {
    var signo = num >= 0 ? 1 : -1;
    num = num * signo;
    if (decimales === 0)
      //con 0 decimales
      return signo * Math.round(num);
    // round(x * 10 ^ decimales)
    num = num.toString().split("e");
    num = Math.round(
      +(num[0] + "e" + (num[1] ? +num[1] + decimales : decimales))
    );
    // x * 10 ^ (-decimales)
    num = num.toString().split("e");
    return signo * (num[0] + "e" + (num[1] ? +num[1] - decimales : -decimales));
  }

  function consumoTotal() {
    var Totales = {
        consumoTotal: 0,
        consumoEficiente: 0,
        consumoIneficiente: 0,
      },
      i = 0;
    for (i = 0; i < data.getDataReport.report_data.length; i++) {
      Totales.consumoTotal += round(
        data.getDataReport.report_data[i].total_consumption
      );
      Totales.consumoEficiente += round(
        data.getDataReport.report_data[i].efficient_consumption
      );
      Totales.consumoIneficiente += round(
        data.getDataReport.report_data[i].inefficient_consumption
      );
    }

    return Totales;
  }

  function porcentaje(valor, valorTotal) {
    if (valorTotal == 0) {
      return 0;
    }
    return Number.parseFloat((valor / valorTotal) * 100).toFixed(1);
  }

  const options = {
    orientation: "landscape",
    unit: "in",
    format: [4, 2],
  };

  if (error) {
    return (
      <div style={{}}>
        <Construction />
      </div>
    );
  }

  if (loading || !reportData || !changeDay) {
    return <Loading />;
  }

  const imageGraphic = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/jpg");

    setGraphic(data.slice(22));
    //var cadena = data.slice(22);
    /* if (typeof link.download === "string") {
      link.href = data;
      link.download = "imgGraph.jpg";
      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }  */
    /* imagePie() */
  };

  const decodePdf = () => {
    const linkSource = `data:application/pdf;base64,${basePdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "Informe de reporte por ocupación.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    /* let pdfWindow = window.open("");
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(basePdf) +
        "'></iframe>"
    ); */
    setLoadingPdf(false);
    setGraphic("");
    setBasePdf("");
  };

  function dataPdf() {
    try {
      pdfGenerator({
        variables: {
          image: [graphic],
          data: { report_data: data.getDataReport.report_data },
          prefix_currency: localStorage.getItem("prefix"),
          kwh_cost: 0,
          startTime: formatDateLocal(parametroMin - 86400000),
          finishTime: "",
          nameProject: localStorage.getItem("nameProject"),
          userName: localStorage.getItem("name"),
          imageProject: localStorage.getItem("imgProject"),
          datePrint: localStorage.getItem("localTime"),
        },
      })
        .then((resul) => {
          setBasePdf(resul.data.pdfOcupationByRoom);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div>
      <div className="_container_graphic">
        <div className="date_picker_and_graph_container">
          <div
            style={{
              marginBottom: 15,
            }}
          >
            {" "}
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
              <Grid container justify="center" style={{ width: "max-content" }}>
                <IconButton
                  color="grey"
                  onClick={() => {
                    before(selectedDate);
                  }}
                > 
                  <ChevronLeft />
                </IconButton>
                <ThemeProvider theme={innerTheme}>
                  <DatePicker
                    okLabel="seleccionar"
                    showTodayButton={true}
                    todayLabel="hoy"
                    id="date-picker-dialog"
                    label={t(
                      "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.DATE"
                    )}
                    format="eeee, dd MMMM"
                    value={selectedDate}
                    onChange={handleDateChange}
                    disableFuture
                    color="primary"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </ThemeProvider>
                <IconButton
                  color="grey"
                  disabled={nextDay}
                  onClick={() => {
                    after(selectedDate);
                  }}
                >
                  <ChevronRight />
                </IconButton>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {t(
                      "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.AREA"
                    )}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={idArea}
                    onChange={handleChange}
                  >
                    {areas.map((item, key) => (
                      <MenuItem key={key} value={item.id}>
                        {item.alias} ({item.status})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </MuiPickersUtilsProvider>
          </div>
          <div className="occupation_report_graph_container" ref={printRef}>
            <OccupancyChart
              data={reportData.getDataReport}
              dateMin={dateMin}
              dateMax={dateMax}
              timeZone={-(project.time_zone * 60)}
            />
          </div>
        </div>

        {/*  borrar el componente de abajo si ha pasado el 15 de febrero del 2024 y no ha suscedido ningun error */}
        {/*  {reportData.getDataReport ? (
          <>
            {dateMax && (
              <div ref={printRef}>
                <OccupancyChart
                  data={reportData.getDataReport}
                  dateMin={dateMin}
                  dateMax={dateMax}
                  timeZone={-(project.time_zone * 60)}
                />
                 <HighchartsReact
                  // constructorType="stockChart"
                  highcharts={Highcharts}
                  options={{
                    title: {
                      text: " ",
                    },
                    yAxis: {
                      min: 0,
                      max: reportData.getDataReport.graph_data.length,
                      tickAmount:
                        reportData.getDataReport.graph_data.length + 1,
                      title: {
                        enabled: false,
                      },
                    },
                    plotOptions: {
                      area: {
                        fillOpacity: 0.4,
                        marker: {
                          enabled: true,
                          symbol: "circle",
                        },
                      },
                      series: {
                        states: {
                          inactive: {
                            enabled: false,
                          },
                        },
                        turboThreshold: 0,
                        marker: {
                          enabled: true,
                          symbol: "circle",
                        },
                        label: {
                          enabled: false,
                        },
                      },
                    },
                    legend: {
                      align: "right",
                      verticalAlign: "top",
                      layout: "vertical",
                      x: 0,
                      y: 0,
                    },
                    xAxis: {
                      type: "datetime",
                      min: dateMin,
                      max: dateMax,
                      tickInterval: 3600000,
                      labels: {
                        format: "{value:%H:%M}",
                      },
                    },
                    time: {
                      timezoneOffset: -(project.time_zone * 60),
                    },
                    chart: {
                      zoomType: "x",
                    },

                    // mapNavigation: {
                    //   enableMouseWheelZoom: true,
                    // },
                    series: data?.getDataReport?.graph_data,
                    tooltip: {
                      xDateFormat: "%H:%M:%S",
                      headerFormat: "<b>{point.key}</b><br>",
                    },
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <>
            <div className="__data_empty__">
              <img
                src={grafica}
                alt="Grafica vacía"
                className="__text_data_empty__"
              />
            </div>
          </>
        )} */}

        <div className="_report_energy">
          <div className="_container_energy">
            <div className="__title_energy">
              <span className="__icon_report">
                <i className="fas fa-chart-bar"></i>
              </span>{" "}
              {t(
                "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.GRAPH_TITLE"
              )}
            </div>
            {reportData.getDataReport ? (
              <>
                <TableContainer component={Paper} className="_container_table">
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">
                          {t(
                            "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.NAME"
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {t(
                            "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.WATTS"
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {t(
                            "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.USAGE_TIME"
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {t(
                            "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.TOTAL_CONSUMPTION"
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">% </StyledTableCell>
                        <StyledTableCell align="center">
                          {t(
                            "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.EFFICIENT_TIME"
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {t(
                            "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.EFFICIENT_CONSUMPTION"
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {t(
                            "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.EFFICIENT_COST"
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {t(
                            "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.INEFFECTIVE_TIME"
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {t(
                            "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.INEFFECTIVE_CONSUMPTION"
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {t(
                            "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.INEFFECTIVE_COST"
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {t(
                            "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.DEVICE_INEFFICIENCY"
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {t(
                            "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.TOTAL_INEFFICIENCY"
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {t(
                            "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.TOTAL_COST"
                          )}
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reportData?.getDataReport?.report_data.map((row) => (
                        <StyledTableRow key={row.name}>
                          <StyledTableCell
                            align="center"
                            component="th"
                            scope="row"
                          >
                            {row?.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row?.watts}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {msToTime(row?.total_time_on)}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {round(row?.total_consumption)} kWh
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {porcentaje(
                              round(row?.total_consumption),
                              round(consumoTotal().consumoTotal)
                            )}
                            %
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {msToTime(row?.efficient_time)}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {round(row?.efficient_consumption)} kWh
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {project?.prefix_currency}{" "}
                            {project?.kwh_cost *
                              round(row?.efficient_consumption)}{" "}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {msToTime(row?.inefficient_time)}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {round(row?.inefficient_consumption)} kWh
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {project?.prefix_currency}{" "}
                            {project?.kwh_cost *
                              round(row?.inefficient_consumption)}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {porcentaje(
                              round(row?.inefficient_consumption),
                              round(row?.total_consumption)
                            )}
                            %
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {porcentaje(
                              round(row?.inefficient_consumption),
                              round(consumoTotal().consumoIneficiente)
                            )}
                            %
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {project?.prefix_currency}{" "}
                            {project?.kwh_cost * round(row?.total_consumption)}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                      <StyledTableRow>
                        <StyledTableCell2 align="center">
                          {t(
                            "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.TOTAL_CONSUMPTION"
                          )}
                        </StyledTableCell2>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell2 align="center">
                          {round(consumoTotal().consumoTotal)} kWh
                        </StyledTableCell2>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell2 align="center">
                          {round(consumoTotal().consumoEficiente)} kWh
                          <br></br>{" "}
                          {round(
                            porcentaje(
                              round(consumoTotal().consumoEficiente),
                              round(consumoTotal().consumoTotal)
                            )
                          )}{" "}
                          %
                        </StyledTableCell2>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell3 align="center">
                          {round(consumoTotal().consumoIneficiente)} kWh
                          <br></br>
                          {porcentaje(
                            round(consumoTotal().consumoIneficiente),
                            round(consumoTotal().consumoTotal)
                          )}{" "}
                          %
                        </StyledTableCell3>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      {t(
                        "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.NAME"
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {t(
                        "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.WATTS"
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {t(
                        "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.USAGE_TIME"
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {t(
                        "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.TOTAL_CONSUMPTION"
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">% </StyledTableCell>
                    <StyledTableCell align="center">
                      {t(
                        "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.EFFICIENT_TIME"
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {t(
                        "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.EFFICIENT_CONSUMPTION"
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {t(
                        "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.EFFICIENT_COST"
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {t(
                        "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.INEFFECTIVE_TIME"
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {t(
                        "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.INEFFECTIVE_CONSUMPTION"
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {t(
                        "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.INEFFECTIVE_COST"
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {t(
                        "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.DEVICE_INEFFICIENCY"
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {t(
                        "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.TOTAL_INEFFICIENCY"
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {t(
                        "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.TOTAL_COST"
                      )}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Occupation;
