export const handle_start_and_end_time = (range) => {
  const start_time = new Date(range?.startDate);
  const end_time = new Date(range?.endDate);

  const start_time_result = `${start_time.getFullYear()}/${start_time.getMonth()+1}/${start_time.getDate()}`;
  const end_time_result = `${end_time.getFullYear()}/${end_time.getMonth()+1}/${end_time.getDate()}`;

  return {
    start: start_time_result,
    end: end_time_result,
  };
};

export const back = (
  id,
  project,
  getData,
  rangeDatePicker,
  setRangeDatePicker
) => {
  if (project?.start_time) {
    const backday = rangeDatePicker[0]?.startDate; // Obtenemos el dia

    let day_time_zone =
      new Date(backday)?.toString()?.substring(0, 16) +
      `${project?.start_time}:00 GMT` +
      project?.time_zone; // Le damos el formato de e.g "Wed Jan 31 2024 00:00:00 GTM", dependiendo de la hora del start time

    let day_time_end_zone =
      new Date(day_time_zone)?.getTime() - (86400000 - 60000); // A la hora que calculamos anterior le sumamos 1 dias menos 1 minuto

    // set el nuevo valor de calendar
    setRangeDatePicker([
      {
        ...rangeDatePicker.selection,
        startDate: new Date(day_time_end_zone),
        endDate: new Date(day_time_end_zone),
        key: "selection",
      },
    ]);

    const { start: initial, end: final } = handle_start_and_end_time({
      startDate: new Date(day_time_end_zone),
      endDate: new Date(day_time_end_zone),
      key: "selection",
    });

    getData({
      variables: {
        ProjectId: parseInt(id),
        start_time: initial,
        end_time: final,
      },
    });
  }
};

export const next = (
  id,
  project,
  getData,
  rangeDatePicker,
  setRangeDatePicker
) => {
  if (project?.start_time) {
    const nextday = rangeDatePicker[0]?.endDate; // Obtenemos el dia

    let day_time_zone =
      new Date(nextday).toString().substring(0, 16) +
      `${project?.start_time}:00 GMT` +
      project?.time_zone; // Le damos el formato de e.g "Wed Jan 31 2024 00:00:00 GTM", dependiendo de la hora del start time

    let day_time_end_zone = new Date(day_time_zone).getTime() + 86400000; // A la hora que calculamos anterior le sumamos 1 dias menos 1 minuto

    // set el nuevo valor de calendar
    setRangeDatePicker([
      {
        ...rangeDatePicker.selection,
        startDate: new Date(day_time_end_zone),
        endDate: new Date(day_time_end_zone),
        key: "selection",
      },
    ]);

    const { start: initial, end: final } = handle_start_and_end_time({
      startDate: new Date(day_time_end_zone),
      endDate: new Date(day_time_end_zone),
      key: "selection",
    });

    getData({
      variables: {
        ProjectId: parseInt(id),
        start_time: initial,
        end_time: final,
      },
    });
  }
};

export const handleQueryReport = (id, rangeDatePicker, getData) => {
  const { start, end } = handle_start_and_end_time(rangeDatePicker[0]);
  getData({
    variables: {
      ProjectId: parseInt(id),
      start_time: start,
      end_time: end,
    },
  });
};
