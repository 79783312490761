import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/principal/App";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  concat,
} from "@apollo/client";
import Providers from "./context";
import { AbilityContext } from "./abilities/Abilities";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
//i18n multilenguage
import i18next from "i18next";
import { Translation, initReactI18next } from "react-i18next";
import en from "./i18n/en.json";
import es from "./i18n/es.json";

// Styles
import "./styles/App.scss";

i18next.use(initReactI18next).init({
  lng:
    localStorage.getItem("language") !== undefined &&
    localStorage.getItem("language") !== null
      ? localStorage.getItem("language")
      : navigator.language.split("-")[0],
  lng:
    localStorage.getItem("language") !== undefined &&
    localStorage.getItem("language") !== null
      ? localStorage.getItem("language")
      : navigator.language.split("-")[0],
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: en,
    },
    es: {
      translation: es,
    },
  },
});
const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? token : "",
    },
  };
});
const {
  REACT_APP_URI_DEVELOP,
  REACT_APP_URI_PRODUCTION,
  REACT_APP_URI_STAGING,
  REACT_APP_URI_PRODUCTION_NEW,
} = process.env;
const MICROSERVICE_MEASURER = process.env.REACT_APP_URI_MICROSERVICE_MEASURER;
//header to upload files
//replace the link
const UploadClient = createUploadLink({
     uri: `${REACT_APP_URI_PRODUCTION}`,
  // uri: `${REACT_APP_URI_DEVELOP}`,
  // uri: `${REACT_APP_URI_STAGING}`,
});
//client created
global.client = new ApolloClient({
  link: concat(authLink, UploadClient),
  cache: new InMemoryCache(),
});

export const secondClient = new ApolloClient({
  uri: MICROSERVICE_MEASURER,

  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={global.client}>
      <Providers />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root") || document.createElement("div")
);
