import gql from "graphql-tag";

export const GET_ALL_PROJECTS = gql`
  query {
    getAllProjects
  }
`;

export const GET_PROJECT_BY_ID = gql`
  query ($id: Int) {
    getAllProjects(id: $id)
  }
`;

export const GET_INFO_USER_LOGGED = gql`
  query ($page: Int, $id: Int) {
    getAllProjectsName
    getInfoUserLogged(id: $id, page: $page, records: 5)
  }
`;

export const FILTER_PROJECT = gql`
  query ($page: Int, $id: Int, $records: Int) {
    projectManagement(id: $id, page: $page, records: $records)
  }
`;

export const GET_ALL_LOGGED_USER_PROJECT = gql`
  query ($projectManagementId: Int, $records: Int, $page: Int) {
    projectManagement(id: $projectManagementId, records: $records, page: $page)
  }
`;

export const GET_ALL_USER_PROJECT = gql`
  query getAllProjectsByUser {
    getAllProjectsByUser
  }
`;

export const CREATE_PROJECT = gql`
  mutation CreateProject(
    $name: String!
    $start_time: String
    $end_time: String
    $time_zone: Float
    $img: String
    $image: String
    $country: String
    $city: String
    $state: String
    $currency: String
    $prefix_currency: String
    $CityId: Int
    $UserId: [Int]
    $controllersCredentials: ControllerCredentials
  ) {
    CreateProject(
      name: $name
      start_time: $start_time
      end_time: $end_time
      time_zone: $time_zone
      img: $img
      image: $image
      country: $country
      city: $city
      state: $state
      currency: $currency
      prefix_currency: $prefix_currency
      CityId: $CityId
      UserId: $UserId
      controllersCredentials: $controllersCredentials
    ) {
      name
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProject(
    $id: Int!
    $name: String
    $start_time: String
    $end_time: String
    $img: String
    $enabled: Boolean
    $country: String
    $city: String
    $state: String
    $currency: String
    $prefix_currency: String
    $time_zone: Float
    $controllersCredentials: ControllerCredentials
  ) {
    UpdateProject(
      id: $id
      name: $name
      start_time: $start_time
      end_time: $end_time
      img: $img
      enabled: $enabled
      country: $country
      city: $city
      state: $state
      currency: $currency
      prefix_currency: $prefix_currency
      time_zone: $time_zone
      controllersCredentials: $controllersCredentials
    )
  }
`;

export const DELETE_PROJECT = gql`
  mutation DeleteProject($id: Int!) {
    DeleteProject(id: $id)
  }
`;
export const GET_ASSOCIATES = gql`
  query getAssociates {
    getAllAssociates {
      id
      name
    }
  }
`;

export const CREATE_COUNTRY = gql`
  mutation createCountry($name: Str!) {
    CreateCountry(name: $name) {
      id
    }
  }
`;

export const CREATE_STATE = gql`
  mutation createState($name: Str, $countryId: id) {
    createState(name: Str, idCountry: int) {
      id
    }
  }
`;

export const CREATE_CITY = gql`
  mutation createCity($name: Str, $stateId: id) {
    createCity(name: Str, stateId: int) {
      id
    }
  }
`;
