import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { Fragment } from "react";
import { Tooltip } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Fab } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import ToggleSwitch from "../../../components/toggleSwitch/ToggleSwitch";
//Components
import { AuthContext } from "../../../context/AuthProvider";
import Loading from "../../../components/loading/Loading";
import { ModalCreateOrEditProject } from "../../../components/projectsManagements/ModalCreateOrEditProject";
import { ModalAlertChangeProjectStatus } from "../projectsManagement/ChangeProjectStatusModal";
//query & mutation
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALL_USER_PROJECT } from "../../../controllers/projectController";
import { dividirArray, imgfilter } from "./helpers/helperNameAndImg";
//multilanguage
import { useTranslation } from "react-i18next";
import { projcetUser, useProjectUser } from "./helpers/hooks/useProject";
const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: 25,
  },
  contents: {
    alignItems: "center",
    justifyContent: "center",
  },
  options: {
    marginBottom: "2%",
    marginTop: "2%",
    justifyContent: "center",
    alignItems: "center",
  },
});
function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function ProjectManagement() {
  const { t } = useTranslation();
  const recordsPerPage = 6;
  const { projectsNameId } = useProjectUser();
  const classes = useStyles();
  const [projectIdd, setProjectIdd] = React.useState("");
  const {
    projects,
    setProjects,
    permissionsRoleUser,
    modalCreateOrEditProject,
    setModalCreateorEditProject,
    openChangeProjectStatusModal,
    setOpenChangeProjectStatusModal,
  } = React.useContext(AuthContext);
  const [selectedProject, setSelectedProject] = useState(null);
  const [inputCleared, setInputCleared] = useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [totalsProjects, setTotalProjects] = useState(0);
  const [currentProjectPage, setCurrentProjectPage] = React.useState(0);
  const { data, loading, error, refetch } = useQuery(GET_ALL_USER_PROJECT, {
    fetchPolicy: "network-only",
  });
  const dataProjects = data?.getAllProjectsByUser?.Projects;

  useEffect(() => {
    if (data) {
      dataProjects?.map((i) => setTotalProjects(i));
      const filteredProjects = dataProjects.filter((project) =>
        project?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      );
      setProjects(filteredProjects);
      setCurrentProjectPage(1);
    }
  }, [data, searchTerm]);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loadingText = open && options.length === 0;
  const lastIndex = currentProjectPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = projects.slice(firstIndex, lastIndex);
  const recordsIds = projectsNameId?.map((project) => project?.id);
  const totalProjectPages = Math.ceil(projects.length / recordsPerPage);
  const [pressedEditButton, setPressedEditButton] = useState(false);
  const [pressedCreateButton, setPressedCreateButton] = useState(false);
  const [selectedCountry, setNewSelectedCountry] = useState(true);
  const [filledNameProject, setNewFilledNameProject] = useState(true);
  const [selectedState, setNewSelectedState] = useState(true);
  const [filledStart_Time, setNewFilledStart_Time] = useState(true);
  const [filledEnd_Time, setNewFilledEnd_Time] = useState(true);
  const [selectedCity, setNewSelectedCity] = useState(true);
  const [filledControlAppUsername, setNewFilledControlAppUsername] = useState(true);
  const [filledControlAppPassword, setNewFilledControlAppPassword] = useState(true);

  const handleOpenCreate = () => {
    setPressedEditButton(false);
    setPressedCreateButton(true);
    setModalCreateorEditProject(!modalCreateOrEditProject);
    setNewFilledNameProject(false);
    setNewSelectedCountry(false);
    setNewSelectedState(false);
    setNewSelectedCity(false);
    setNewFilledStart_Time(true);
    setNewFilledEnd_Time(true);
    setNewFilledControlAppUsername(false);
    setNewFilledControlAppPassword(false);
  };
  const handleOpenEdit = (id) => {
    setProjectIdd(id);
    setPressedEditButton(true);
    setPressedCreateButton(false);
    setModalCreateorEditProject(true);
    setNewFilledNameProject(true);
    setNewSelectedCountry(true);
    setNewSelectedState(true);
    setNewSelectedCity(true);
    setNewFilledStart_Time(true);
    setNewFilledEnd_Time(true);
    setNewFilledControlAppUsername(true);
    setNewFilledControlAppPassword(true);
  };
  const handleOpenChangeProjectStatus = () => {
    setOpenChangeProjectStatusModal(true);
  };

  React.useEffect(() => {
    let active = true;
    if (!loadingText) {
      return undefined;
    }
    (async () => {
      await sleep(1e3);
      if (active) {
        const filteredOptions = dataProjects?.filter((project) =>
          project?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        );
        setOptions([...filteredOptions]);
      }
    })();
    return () => {
      active = false;
    };
  }, [loadingText, searchTerm]);
  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const preProjectPage = () => {
    if (currentProjectPage !== 0) {
      if (currentProjectPage !== 0) {
        setCurrentProjectPage(currentProjectPage - 1);
      }
    }
  };
  const changeCurrentProjectPage = (id, projectName) => {
    setCurrentProjectPage(id);
    setSearchTerm(projectName);
  };
  const nextPage = () => {
    if (currentProjectPage !== totalProjectPages) {
      setCurrentProjectPage(currentProjectPage + 1);
    }
  };

  const handleInputChange = (event) => {
    const inputName = event.target.value;
    const matchingProjects = dataProjects?.filter((project) =>
      project?.name?.toLowerCase()?.includes(inputName?.toLowerCase())
    );
    setOptions([...matchingProjects]);
    setSearchTerm(inputName);
    setInputCleared(false);
  };

  const handleAutocompleteChange = (event, value) => {
    if (value) {
      setSelectedProject(value);
      setSearchTerm(value?.name);
    } else {
      setSelectedProject(null);
      setSearchTerm("");
      setInputCleared(true);
      setCurrentProjectPage(1);
    }
  };

  if (error) {
    return (
      <div>
        <lottie-player
          style={{
            width: "70%",
            marginLeft: "30%",
            marginRight: "auto",
          }}
          className="_logo"
          src="https://assets1.lottiefiles.com/private_files/lf30_tonsVH.json"
          background="transparent"
          speed="1"
          loop
          autoplay
        ></lottie-player>
      </div>
    );
  }
  if (loading) {
    return (
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </div>
    );
  }
  return (
    <Fragment>
      {permissionsRoleUser?.permissionsRole?.GesProjectVer ? (
        <div className="project_datatable_container">
          <div className="project_container_header">
            <div className="table_title_container">
              <h1 className="table_title">
                {t(
                  "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_PROJECT_TABLE.TABLE_TITLE"
                )}
              </h1>
            </div>
            <div className="container_button_and_filter">
              <div className="content_header_project_button">
                {permissionsRoleUser?.permissionsRole?.GesProjectCrear ? (
                  <Tooltip title="Agregar Proyecto">
                    <button
                      className="project_button_agreger"
                      onClick={handleOpenCreate}
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                  </Tooltip>
                ) : null}
              </div>
              <div className="project_filter_container">
                <Autocomplete
                  id="project_filter"
                  value={options}
                  inputValue={inputCleared ? "" : searchTerm}
                  disableClearable={true}
                  onChange={handleAutocompleteChange}
                  open={open}
                  onInput={handleInputChange}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  getOptionLabel={(option) => option.name}
                  options={options}
                  loading={loadingText}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t(
                        "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_PROJECT_TABLE.SEARCH_INPUT"
                      )}
                      size="small"
                      value={searchTerm}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingText ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                        sx: { borderRadius: 2 },
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="table_container">
            <table className="project_datatable">
              <thead className="project_container_title_table">
                <tr>
                  <th>
                    <h3 className="project_img">
                      {t(
                        "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_PROJECT_TABLE.LOGO"
                      )}
                    </h3>
                  </th>
                  <th>
                    <h3 className="project_name">
                      {t(
                        "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_PROJECT_TABLE.NAME"
                      )}
                    </h3>
                  </th>
                  <th>
                    <h3 className="project_country">
                      {t(
                        "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_PROJECT_TABLE.COUNTRY"
                      )}
                    </h3>
                  </th>
                  <th>
                    <h3 className="project_state">
                      {t(
                        "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_PROJECT_TABLE.STATE"
                      )}
                    </h3>
                  </th>
                  <th>
                    <h3 className="project_city">
                      {t(
                        "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_PROJECT_TABLE.CITY"
                      )}
                    </h3>
                  </th>
                  <th>
                    <h3 className="project_status">
                      {t(
                        "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_PROJECT_TABLE.STATUS"
                      )}
                    </h3>
                  </th>
                  <th>
                    <h3 className="project_edit">
                      {t(
                        "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_PROJECT_TABLE.MODIFICATIONS"
                      )}
                    </h3>
                  </th>
                </tr>
              </thead>
              {records?.map((item, key) => (
                <tbody key={key} className="project_container_table_body">
                  <>
                    <tr>
                      <td className="project_container_img">
                        <img
                          className="project_logo"
                          alt="logo"
                          src={item?.img}
                        />
                      </td>
                      <td className="project_container_name">{item?.name}</td>
                      <td className="project_container_country">
                        {item?.country === null ? item?.Country : item?.country}
                      </td>
                      <td className="project_container_state">
                        {item?.state === null ? item?.State : item?.state}
                      </td>
                      <td className="project_container_city">
                        {item?.city === null ? item?.City : item?.city}
                      </td>
                      <td className="main_project_status_container">
                        <span
                          className={`${
                            item?.enabled
                              ? "container_status_active_project"
                              : "container_status_inactive_project"
                          }`}
                        >
                          {String(
                            item?.enabled === false
                              ? t(
                                  "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_PROJECT_TABLE.INACTIVE"
                                )
                              : t(
                                  "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_PROJECT_TABLE.ACTIVE"
                                )
                          )}
                        </span>
                      </td>
                      <td>
                        <div className="project_container_acctions">
                          {permissionsRoleUser?.permissionsRole
                            ?.GesRolEditar ? (
                            <Tooltip
                              title={t(
                                "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_PROJECT_TABLE.MODAL_EDIT_TOOLTIP"
                              )}
                            >
                              <ModeEditIcon
                                className="project_edit_button"
                                onClick={() => handleOpenEdit(item?.id)}
                              />
                            </Tooltip>
                          ) : null}
                          {permissionsRoleUser.permissionsRole
                            ?.GesProjectDes ? (
                            <ToggleSwitch
                              statusRefetch={refetch}
                              projects={dataProjects}
                              currentIdProject={item?.id}
                              currentProjectStatus={item?.enabled}
                            />
                          ) : null}
                        </div>
                      </td>
                    </tr>
                  </>
                </tbody>
              ))}
            </table>
          </div>
        </div>
      ) : null}
      <div className="projects_pagination">
        <Fab
          size="small"
          aria-label="left"
          className="projects_pagination_button"
          onClick={preProjectPage}
          disabled={currentProjectPage <= 1}
        >
          <ChevronLeftIcon onClick={preProjectPage} />
        </Fab>
        <span>
          {currentProjectPage} / {totalProjectPages}
        </span>
        <Fab
          size="small"
          aria-label="right"
          className="projects_pagination_button"
          onClick={nextPage}
          disabled={
            currentProjectPage === totalProjectPages || totalProjectPages == 0
          }
        >
          <ChevronRightIcon onClick={nextPage} />
        </Fab>
      </div>
      <ModalCreateOrEditProject
        projectIdd={projectIdd}
        pressedEditButton={pressedEditButton}
        setPressedEditButton={setPressedEditButton}
        pressedCreateButton={pressedCreateButton}
        setPressedCreateButton={setPressedCreateButton}
        filledNameProject={filledNameProject}
        setNewFilledNameProject={setNewFilledNameProject}
        selectedCountry={selectedCountry}
        setNewSelectedCountry={setNewSelectedCountry}
        selectedState={selectedState}
        setNewSelectedState={setNewSelectedState}
        selectedCity={selectedCity}
        setNewSelectedCity={setNewSelectedCity}
        filledStart_Time={filledStart_Time}
        setNewFilledStart_Time={setNewFilledStart_Time}
        filledEnd_Time={filledEnd_Time}
        setNewFilledEnd_Time={setNewFilledEnd_Time}
        setRefetchProjectTable={refetch}
        imgFilter={dataProjects}
        filledControlAppUsername={filledControlAppUsername}
        setNewFilledControlAppUsername={setNewFilledControlAppUsername}
        filledControlAppPassword={filledControlAppPassword}
        setNewFilledControlAppPassword={setNewFilledControlAppPassword}
      />
      <ModalAlertChangeProjectStatus
        recordsIds={recordsIds}
        refetchTable={refetch}
      />
    </Fragment>
  );
}
