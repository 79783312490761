import { Fragment } from "react";
import React, {
  useContext,
  useEffect,
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import { AuthContext } from "../../context/AuthProvider";
import { CardPhaseANew } from "./NewMesurersCards/CardPhaseANew";
import { CardPhaseBNew } from "./NewMesurersCards/CardPhaseBNew";
import { CardPhaseCNew } from "./NewMesurersCards/CardPhaseCNew";
import { Dates } from "./MeasurerDetails/helperDate";
import { timeConvertMacromedidor } from "../../utils/helpers";

import CardTotalNew from "./NewMesurersCards/CardTotalNew";
import { useQuery, useMutation } from "@apollo/client";

import TabNewGraphicVAV from "./NewMeasurerDetails/TabNewGraphicVAV";
import { TabNewGraphic } from "./NewMeasurerDetails/TabNewGraphic";
import { secondClient } from "../../index";
import { GET_METTERS_HISTORY } from "../../controllers/measurersController";
import TableConsumptions from "./NewMeasurerDetails/TableConsumptions";
import html2canvas from "html2canvas";

//Functions
import { formatDateLocal, percentage_total } from "../../utils/helpers";

//Controllers
import { PDF_MACROMETER } from "../../controllers/pdfControllers";

const NewMeasurerDetails = forwardRef((props, ref) => {
  const { serial, dato, city, startTimeProject } = props;
  const { data } = useQuery(GET_METTERS_HISTORY, {
    client: secondClient,
    variables: { serial: serial },
    fetchPolicy: "no-cache",
  });

  const {
    seeGraphic,
    setSeeGraphic,
    graphic,
    setGraphic,
    pie,
    setPie,
    setLoadingPdf,
    dateMUI,
    rangeDatePicker,
  } = useContext(AuthContext);
  const [dataTable, setDataTable] = useState("");
  const [totalConsumptionTable, setTotalConsumptionTable] = useState("");
  const [createMacrometerPdf] = useMutation(PDF_MACROMETER);

  const codigosCiudades = {
    Bogotá: "BG",
    Barranquilla: "BQ",
    default: "BQ",
  };
  Object.freeze(codigosCiudades);

  const [basePdf, setBasePdf] = useState();
  const printRef = useRef();
  const printRef2 = useRef();

  const formatedTotalPhasesWattsA = percentage_total(
    parseInt(data?.getMeterHistory[0]?.PAFA)?.toString()
  );
  const formatedTotalPhasesWattsB = percentage_total(
    parseInt(data?.getMeterHistory[0]?.PAFB)?.toString()
  );
  const formatedTotalPhasesWattsC = percentage_total(
    parseInt(data?.getMeterHistory[0]?.PAFC)?.toString()
  );

  const tlmKwh = dato?.TImKwh;

  const totalPhasesWattsA = data?.getMeterHistory[0]?.PAFA;
  const totalPhasesWattsB = data?.getMeterHistory[0]?.PAFB;
  const totalPhasesWattsC = data?.getMeterHistory[0]?.PAFC;

  const totalPhasesVoltageA = data?.getMeterHistory[0]?.VFA;
  const totalPhasesVoltageB = data?.getMeterHistory[0]?.VFB;
  const totalPhasesVoltageC = data?.getMeterHistory[0]?.VFC;

  const totalPhasesAmperageA = data?.getMeterHistory[0]?.CFA;
  const totalPhasesAmperageB = data?.getMeterHistory[0]?.CFB;
  const totalPhasesAmperageC = data?.getMeterHistory[0]?.CFC;

  const totalPhasesPowerFactorA = data?.getMeterHistory[0]?.FPFA;
  const totalPhasesPowerFactorB = data?.getMeterHistory[0]?.FPFB;
  const totalPhasesPowerFactorC = data?.getMeterHistory[0]?.FPFC;

  // const init_time = (time) => {
  //   const time_now = new Date().getHours();
  //   if (time_now === 15 && time === "15:00") {
  //     const time_actual = new Date().getTime() - 86400000;
  //     return new Date(time_actual);
  //   } else {
  //     return Dates(time);
  //   }
  // };

  // const [pdfFormatStartTime, setPdfFormatStartTime] = useState(dateMUI);
  // const [parameterMax, setParameterMax] = useState(new Date());

  useEffect(() => {
    setSeeGraphic(true);
  }, []);

  useEffect(() => {
    if (graphic) {
      dataPdf();
    }
  }, [graphic]);

  useEffect(() => {
    if (basePdf) {
      decodePdf();
    }
  }, [basePdf]);

  useImperativeHandle(ref, () => ({
    async macrometerPdf() {
      setLoadingPdf(true);
      await imageGraphic();
    },
  }));

  const imageGraphic = async () => {
    const element = printRef.current;
    const element2 = printRef2.current;
    const canvas = await html2canvas(element);
    const canvas2 = await html2canvas(element2);
    const data = canvas.toDataURL("image/jpg");
    const data2 = canvas2.toDataURL("image/jpg");
    setGraphic(data.slice(22));
    setPie(data2.slice(22));
  };

  const decodePdf = () => {
    const linkSource = `data:application/pdf;base64,${basePdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "Informe De Macromedidor.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    setLoadingPdf(false);
    setBasePdf("");
    setGraphic("");
    setPie("");
  };

  const dataConsumptionTable = (data) => {
    const dataTable = data?.getConsumptionHistory?.totalBy?.activeEnergy;
    const total = data?.getConsumptionHistory?.currentConsumption?.activeEnergy;
    setDataTable(dataTable);
    setTotalConsumptionTable(total);
  };

  // fechas para la descarga de PDF

  function dataPdf() {
    try {
      const variables = {
        serial: serial,
        location: codigosCiudades[city] || codigosCiudades.default,
        year: new Date().getFullYear() % 100,
        fullYear: parseInt(timeConvertMacromedidor().year),
        // month: parseInt(timeConvertMacromedidor().month),
        data: {
          macrometer: {
            lectura: tlmKwh,
            pie: [
              {
                fase: "A",
                value: totalPhasesWattsA,
                voltaje: totalPhasesVoltageA,
                amperaje: totalPhasesAmperageA,
                factorP: totalPhasesPowerFactorA,
                percentageGraph: formatedTotalPhasesWattsA,
              },
              {
                fase: "B",
                value: totalPhasesWattsB,
                voltaje: totalPhasesVoltageB,
                amperaje: totalPhasesAmperageB,
                factorP: totalPhasesPowerFactorB,
                percentageGraph: formatedTotalPhasesWattsB,
              },
              {
                fase: "C",
                value: totalPhasesWattsC,
                voltaje: totalPhasesVoltageC,
                amperaje: totalPhasesAmperageC,
                factorP: totalPhasesPowerFactorC,
                percentageGraph: formatedTotalPhasesWattsC,
              },
            ],
          },
          dataTable: dataTable,
          total: totalConsumptionTable,
        },
        startTime: rangeDatePicker[0]?.startDate,
        startTimeEpoch: parseInt(Dates(dateMUI).start),
        finishTime: rangeDatePicker[0]?.endDate,
        finishTimeEpoch: parseInt(Dates(dateMUI).end),
        nameProject: localStorage.getItem("nameProject"),
        userName: localStorage.getItem("name"),
        imageProject: localStorage.getItem("imgProject"),
        datePrint: localStorage.getItem("localTime"),
      };
      if (pie) {
        variables?.image?.push(pie);
      }
      createMacrometerPdf({
        variables,
      })
        .then((result) => {
          setBasePdf(result.data.pdfMacrometer);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Fragment>
      <Box
        sx={{
          flexGrow: 1,
          marginTop: 5,
          backgroundColor: "rgba(195, 1, 78, 0.)",
        }}
      >
        <Grid container spacing={1} sx={{ mx: "1%", mt: "40px" }}>
          <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
            <CardTotalNew serial={serial} measurerData={dato} />
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
            <CardPhaseANew
              MeasurerDataPhases={data?.getMeterHistory[0]}
              serial={serial}
            />
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
            <CardPhaseBNew
              serial={serial}
              MeasurerDataPhases={data?.getMeterHistory[0]}
            />
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
            <CardPhaseCNew
              serial={serial}
              MeasurerDataPhases={data?.getMeterHistory[0]}
            />
          </Grid>

          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <div ref={printRef}>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ height: "40px" }}
              ></Grid>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ height: "550px" }}
              >
                <TabNewGraphic serial={serial} city={city} />
              </Grid>
            </div>

            <div ref={printRef2}>
              {seeGraphic === true ? (
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ height: "550px" }}
                >
                  <TabNewGraphicVAV serial={serial} />
                </Grid>
              ) : (
                <></>
              )}
            </div>
          </div>

          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ width: "90%" }}
          >
            {seeGraphic === true ? (
              <TableConsumptions
                serial={serial}
                city={city}
                dataConsumptionTable={dataConsumptionTable}
              />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
});

export default NewMeasurerDetails;
