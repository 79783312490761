import React, { useContext } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../context/AuthProvider";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
function MessageError(props) {
  const classes = useStyles();
  const { messageText } = props;
  const { errorMessage, setErrorMessage } = useContext(AuthContext);
  const errorMessageText = messageText !== undefined ? messageText : "Fuera de rango" 
  
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorMessage(!errorMessage);
  };

  return (
    // <div className={classes.root}>
    <Snackbar open={errorMessage} autoHideDuration={2000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error">
        {errorMessageText ? errorMessageText : "Comunicación fallida"}
      </Alert>
    </Snackbar>
    //</div>
  );
}

export default MessageError;
