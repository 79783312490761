// Copies
export const copies = {
  RANGE: "range",
};

// Constants
const DAYS = {
  Mon: "Lun",
  Tue: "Mar",
  Wed: "Mie",
  Thu: "Jue",
  Fri: "Vie",
  Sat: "Sab",
  Sun: "Dom",
};

const MONTHS = {
  Jan: "Ene",
  Feb: "Feb",
  Mar: "Mar",
  Apr: "Abr",
  May: "May",
  Jun: "Jun",
  Jul: "Jul",
  Aug: "Ago",
  Sep: "Sep",
  Oct: "Oct",
  Nov: "Nov",
  Dec: "Dic",
};

// Helpers functions
export const format_date_range = (range) => {
  const day_week_start = range[0]?.startDate?.toString()?.substring(0, 3);
  const key_month_start = range[0]?.startDate?.toString()?.substring(4, 7);
  const day_start = range[0]?.startDate?.toString()?.substring(8, 10);
  const year_start = range[0]?.startDate?.toString()?.substring(11, 15);
  const day_week_end = range[0]?.endDate?.toString()?.substring(0, 3);
  const key_month_end = range[0]?.endDate?.toString()?.substring(4, 7);
  const day_end = range[0]?.endDate?.toString()?.substring(8, 10);
  const year_end = range[0]?.endDate?.toString()?.substring(11, 15);
  const end_range =
    new Date(range[0]?.startDate?.toString())?.getTime() ===
    new Date(range[0]?.endDate?.toString())?.getTime()
      ? ""
      : `- ${DAYS[day_week_end]} ${day_end}, ${MONTHS[key_month_end]} ${year_end}`;
  return `${DAYS[day_week_start]} ${day_start}, ${MONTHS[key_month_start]} ${year_start} ${end_range}`;
};

export const format_date_calendar = (date) => {
  const day_week_start = date?.toString()?.substring(0, 3);
  const key_month_start = date?.toString()?.substring(4, 7);
  const day_start = date?.toString()?.substring(8, 10);
  const year_start = date?.toString()?.substring(11, 15);

  return `${DAYS[day_week_start]} ${day_start}, ${MONTHS[key_month_start]} ${year_start}`;
};

export const is_hotel_hours = (type, range, calendar) => {
  const compare_date = new Date();
  const day_compare = compare_date?.toString()?.substring(0, 16);
  const hours_day = compare_date.getHours();
  const day_calendar = range[0]?.startDate?.toString()?.substring(0, 16);
  const day_calendar_end = range[0]?.endDate?.toString()?.substring(0, 16);

  if (type === "range") {
    if (hours_day < 15) {
      return new Date(day_calendar).getTime() ==
        new Date(day_compare).getTime() - 172800000 ||
        new Date(day_calendar_end).getTime() ==
          new Date(day_compare).getTime() - 172800000
        ? false
        : true;
    } else {
      return new Date(day_calendar).getTime() ==
        new Date(day_compare).getTime() - 86400000 ||
        new Date(day_calendar_end).getTime() ==
          new Date(day_compare).getTime() - 86400000
        ? false
        : true;
    }
  } else {
    const day = calendar?.toString()?.substring(0, 16);
    if (hours_day < 15) {
      return new Date(day).getTime() ==
        new Date(day_compare).getTime() - 86400000
        ? false
        : true;
    } else {
      return new Date(day).getTime() == new Date(day_compare).getTime()
        ? false
        : true;
    }
  }
};

export const is_active_btn_next_day = (
  type,
  rangeDatePicker,
  calendar,
  timerProject
) => {
  const compare_date = new Date();
  const hours_day = compare_date.getHours();
  const day_compare = compare_date?.toString()?.substring(0, 16);
  const day_calendar = rangeDatePicker[0]?.startDate
    ?.toString()
    ?.substring(0, 16);
  const day_calendar_end = rangeDatePicker[0]?.endDate
    ?.toString()
    ?.substring(0, 16);

  if (timerProject === "15:00") {
    return is_hotel_hours(type, rangeDatePicker, calendar);
  } else if (timerProject === "00:00" || timerProject === "0:00") {
    if (type === "range") {
      if (hours_day >= 0) {
        return new Date(day_calendar).getTime() ==
          new Date(day_compare).getTime() - 86400000 ||
          new Date(day_calendar_end).getTime() ==
            new Date(day_compare).getTime() - 86400000
          ? false
          : true;
      } else {
        return new Date(day_calendar).getTime() ==
          new Date(day_compare).getTime() - 172800000 ||
          new Date(day_calendar_end).getTime() ==
            new Date(day_compare).getTime() - 172800000
          ? false
          : true;
      }
      // const day_start = rangeDatePicker[0]?.startDate
      //   ?.toString()
      //   ?.substring(0, 16);
      // const day_end = rangeDatePicker[0]?.endDate?.toString()?.substring(0, 16);
      // return (
      //   new Date(day_start).getTime() === new Date(day_end).getTime() &&
      //   new Date(day_compare).getTime() !== new Date(day_start).getTime()
      // );
    } else {
      const day = calendar?.toString()?.substring(0, 16);
      return new Date(day).getTime() !== new Date(day_compare).getTime();
    }
  }
};

export const handle_status = (status) => {
  if (!status) return "VIEW_DATE_PICKER.STATUS_STATELESS";
  const values = {
    0: "VIEW_DATE_PICKER.STATUS_FREE",
    25: "VIEW_DATE_PICKER.STATUS_DIRTY",
    50: "VIEW_DATE_PICKER.STATUS_SOLD",
    75: "VIEW_DATE_PICKER.STATUS_CLEANING",
    100: "VIEW_DATE_PICKER.STATUS_BLOCKED",
    125: "VIEW_DATE_PICKER.STATUS_BOOKED",
    Libre: "VIEW_DATE_PICKER.STATUS_FREE",
    Ocupado: "VIEW_DATE_PICKER.STATUS_BUSY",
  };
  return values[status] ?? "VIEW_DATE_PICKER.STATUS_STATELESS";
};
