import React, { useEffect, useContext } from "react";
import { green } from "@material-ui/core/colors";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { AuthContext } from "../../context/AuthProvider";
import { Devices } from "../../constants/devices_types";
import {
  statePluginMotelConvert,
  statePluginMotel,
  filterByPluggin,
} from "../../utils/helpers";

//multilanguage
import { useTranslation } from "react-i18next";

const innerTheme = createTheme({
  palette: {
    secondary: {
      main: green[500],
    },
  },
});

const HorusMotel = ({ item, send }) => {
  const { t } = useTranslation();
  const {
    infoMessage,
    setInfoMessage,
    successMessage,
    setSuccessMessage,
    errorMessage,
    setErrorMessage,
  } = useContext(AuthContext);
  const [state, setState] = React.useState(
    item?.variables?.Estado == "1" ? true : false
  );
  const [load, setLoad] = React.useState(false);
  useEffect(() => {
    setState(item?.variables?.Estado == "1" ? true : false);
  }, [item]);

  useEffect(() => {
    setLoad(false);
  }, [errorMessage, successMessage]);

  const translatingPluginName = () => {
    const pluginTitle = typeof item?.name === "string" ? item?.name : "";
    if (pluginTitle?.toLowerCase() === "plugin motel") {
      return t("VIEW_AREA.COMPONENT_MOTEL_PLUGIN_CARD.HEADER_TITLE");
    } else {
      return pluginTitle;
    }
  };

  return (
    <div className="__pluginMotel_principal_">
      <div className="__title_pluginMotel">
        <div className="__title__">{translatingPluginName()}</div>
        <div className="__img__"></div>
      </div>
      <div className="__content_pluginMotel">
        <div className="__img_logo_pluginMotel">
          <img src={Devices.DESOCUPADA} alt="home" width="80" height="80" />
        </div>
        <div className="__content_state_pluginMotel">
          <div className="__title_text_state">
            {t("VIEW_AREA.COMPONENT_MOTEL_PLUGIN_CARD.CURRENT_STATUS")}
          </div>
          <div
            className="__state_actual_pluginMotel"
            style={{
              border: `1px solid ${
                // filterByPluggin([item])
                statePluginMotelConvert(item?.variables?.Estado, t).border
              }`,
              backgroundColor: `${
                statePluginMotelConvert(item?.variables?.Estado, t).bg
              }`,
              color: `${
                statePluginMotelConvert(item?.variables?.Estado, t).color
              }`,
            }}
          >
            {statePluginMotelConvert(item?.variables?.Estado, t).text}
          </div>
        </div>
      </div>
      <div className="__other_states_pluginMotel__">
        {t("VIEW_AREA.COMPONENT_MOTEL_PLUGIN_CARD.OTHER_STATES")}
      </div>
      <div className="__content_states_pluginMotel">
        {statePluginMotel(item?.variables?.Estado, t).map((i) => (
          <div className="__item_states_pluginMotel">{i?.name}</div>
        ))}
      </div>
    </div>
  );
};

export default HorusMotel;
